<template>
  <div class="container mw596">
    <div class="row pt-52 pt-md-32">
      <div class="col-2">
        <b-button @click="selectMethod('AddFundsMethod', 'back')" variant="xxs">
          <i class="icon-previous fs9 mr-10 text-gray-600"></i>
          <span class="fs12 lh14 fw500 wsnw"> Go back </span>
        </b-button>
      </div>
      <div class="col-8 my-15">
        <div class="d-flex flex-column align-items-center">
          <img src="@/assets/svg/paytm.svg" style="height: 3rem" alt="" />
          <span class="fs16 lh24 mt-24"> <strong>Submit Paytm Amount</strong> </span>
        </div>
      </div>
    </div>

    <div class="row pt-32 pb-8">
      <div class="col">
        <b-form-group
          id="amount-field"
          label="Enter Amount"
          label-for="amount-value"
        >
        <b-input-group append="₹">
          <b-input
            type="number"
            id="amount-value"
            placeholder="0.00"
            v-model="coupon"
            :disabled="this.$store.state.rateAmount != '' "
          ></b-input>
        </b-input-group>
        </b-form-group>
      </div>
    </div>

    <div v-if="planId && this.$store.state.rateAmount < 200" class="row col-md-12 mx-auto info-card">
      As the minimum recharge amount needs to be maintained at Rs.200, you are requested to recharge with Rs.200 to activate the selected plan.
    </div>

    <div id="form" v-if="formrows">
      <center>
        <h1>Please do not refresh this page...</h1>
      </center>
      <b-form
        ref="form"
        method="post"
        :action="transactionUrl"
        name="paytm"
        Content-Type="application/x-www-form-urlencoded"
      >
        <table border="1">
          <tbody v-html="formrows"></tbody>
        </table>

        <input
          style="display: none"
          type="submit"
          ref="formSubmit"
          id="formSubmit"
        />
      </b-form>
    </div>

    <div class="row">
      <div class="col pt-15 pt-md-15 pb-30 pb-md-30 text-right">
        <b-button
          type="button"
          @click="selectMethod('AddFundsSuccessfull', 'submit')"
          class="w-sm-100 btn btn-secondary"
        >
          <span class="fw700 fs18" style="color: #14a8e6"> Continue to</span><img class="ml-5 paytm" src="@/assets/images/paytm.png" alt="" /><b-spinner v-show="paytmspinner" class="paytmspinner ml-10"></b-spinner>
          <i class="icon-expand-menu fs7 ml-5" style="color: #14a8e6"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddFundsCoupon",
  data() {
    return {
      planId:this.$store.state.global.planId,
      coupon: this.$store.state.global.planId ? (this.$store.state.rateAmount < 200 ? 200 : this.$store.state.rateAmount) : "",
      formrows: "",
      paytmForm: {},
      transactionUrl: "",
      paytmspinner:false
    };
  },
  methods: {
    async selectMethod(variable, path) {
      if (path == "back") {
        this.$emit("selectTab", variable);
      } else {
        this.paytmspinner = true;
        try {
          const response = await this.axios.post("/paytm", {
            amount: this.coupon, templateID: this.planId
          });
          let data = response.data;
            if(data.error){
              this.popToast("failed", "Failed!", data.error);
            } else {
              let rows = Object.keys(data.result).map((i) =>`<input type="hidden" name="${i}" value="${data.result[i]}" />`).join("");
              this.formrows = rows;
              this.transactionUrl = data.transactionURL;
            }
          
        } catch (error) {
          console.log(error);
        }
        this.paytmspinner = false;
      }
    },
  },
  computed: {
    
  },
  updated() {
    if (this.formrows) {
      this.$refs.formSubmit.click();
    }
  },
};
</script>

<style type="text/css" scoped>
  .paytm {
    max-height: 3rem;
    max-width: 3rem;
  }
  .paytmspinner {
    width: 1rem !important;
    height: 1rem !important;
    color: rgb(20, 168, 230) !important;
  }
  .info-card {
    font-weight: 700;
    padding: 0.3rem 0.8rem;
    background-color: #e5f0ff;
    border-radius: 5px;
  }
</style>