<template>
  <div class="container mw596">
    <div class="row pt-52 pt-md-32">
      <div class="col-2">
        <b-button @click="selectMethod('AddFundsMethod', 'back')" variant="xxs">
          <i class="icon-previous fs9 mr-10 text-gray-600"></i>
          <span class="fs12 lh14 fw500 wsnw"> Go back </span>
        </b-button>
      </div>
      <div class="col-8 my-15">
        <div class="d-flex flex-column align-items-center">
          <img src="@/assets/svg/bharatpe.svg" style="height: 3.3rem" alt="" />
          <span class="fs16 lh24 mt-24"> <strong>Submit BharatPe Amount</strong> </span>
        </div>
      </div>
    </div>

    <div class="row pt-32 pb-8">
      <div class="col">
        <b-form-group
          id="amount-field"
          label="Amount"
          label-for="amount-value"
        >
        
        <b-input-group append="₹">
          <b-input
            id="amount-value"
            placeholder="Enter Amount"
            v-model="coupon"
          ></b-input>
        </b-input-group>
        </b-form-group>
      </div>
    </div>

    <div class="row pt-0 pt-md-10 pb-0 pb-md-18">
      <div class="col text-right">
        <b-link v-if="svg" href="/billing">Click here after Successfull Payment</b-link>
        <b-button
          @click="selectMethod('AddFundsSuccessfull', 'submit')"
          variant="primary"
          class="w-sm-100"
          v-else
        >
          <span class="fw700"> Submit </span>
        </b-button>
      </div>
    </div>
      <div v-if="svg" class="mx-auto pb-40 svg-bharatpe">
        <img class="w-100" src="@/assets/images/bp1.jpg">
        <div v-html="svg"></div>
        <img class="w-100" src="@/assets/images/bp2.jpg">
    </div>
  </div>

  
</template>

<script>
export default {
  name: "AddFundsBharatPe",
  data() {
    return {
      coupon: "",
      svg:""
    };
  },
  methods: {
    async selectMethod(variable, path) {
      if (path == "back") {
        this.$emit("selectTab", variable);
      } else {
        try {
          const res = await this.axios.post('/bharatpe',{amount:this.coupon})
          console.log(res)
          if (res.data.status) {
            this.svg = res.data.body.svgString
          } else {
            this.popToast("failed", "Failed!", res.data.error);
            this.svg = ""
          }
        } catch(e) {
          this.popToast("failed", "Failed!", "Code Generation Failed!! Please Try Again Later");
          console.log(e);
        }
      }
    },
  },
};
</script>

<style type="text/scss" scoped>
  .svg-bharatpe {
    width: 30% !important;
  }
</style>
