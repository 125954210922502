export default {
    methods: {

        maxten(e) {
            return String(e).substring(0, 10);
        },

        maxsix(e) {
            return String(e).substring(0, 6);
        },

        numbers(e) {
            return String(e).replace(/[^0-9]/g,'');
        },

        alphaNumbric(e) {
            return String(e).replace(/[^A-Za-z0-9]/g,'');
        },

        characterOnly(e) {
            return String(e).replace(/[^A-Za-z]/g,'');
        },

        spaceAlphaNumbric(e) {
            return String(e).replace(/[^A-Za-z0-9 ]/g,'');
        },

        alphaNumbricHyphen(e) {
            return String(e).replace(/[^A-Za-z0-9-]/g,'');
        },

        customForIOSSNumber(e) {
            return String(e).replace(/[a-zA-Z]{2}\d{10}/g,'');
        }
    }
}