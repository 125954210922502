<template>
  <div class="container">
    <div class="row pt-134 pb-140 pt-md-72 pb-md-72">
      <div class="col text-center">
        <img
          class="pb-24"
          src="@/assets/svg/payment-successfull.svg"
          alt="Payment Succesfull!"
        />
        <span class="fs24 lh32 d-block fw500 pb-16">
          Payment Successfull
        </span>
        <p v-if="amountupdated" class="fs16 lh24 mx-auto text-center mw366 pb-32">
          You have successfully added {{amountupdated | currency('inr')}} to your account. You will be
          redirected to to your dashboard within 5 seconds.
        </p>
        <p v-if="paytmamount" class="fs16 lh24 mx-auto text-center mw366 pb-32">
          You have successfully added {{paytmamount | currency('inr')}} to your account. You will be
          redirected to to your dashboard within 5 seconds.
        </p>
        <b-button @click="selectMethod('AddFundsMethod')" variant="primary">
          Okay, got it!
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddFundsSuccessfull",
  props:{
    amountupdated:{
      type:String
    },
    paytmamount:{
      type:String
    }
  },
  methods: {
    selectMethod(variable) {
      this.$bvModal.hide("BillingAddFunds");
      this.$emit("selectTab", variable);
      if (this.$route.path != "/dashboard") {
        this.$router.push("/dashboard");
      }
    },
  },
};
</script>
