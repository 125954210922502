var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.clickOutsideSearch,
          expression: "clickOutsideSearch"
        }
      ],
      staticClass: "search",
      class: { search__open: _vm.searchToggle }
    },
    [
      _c(
        "b-button",
        {
          staticClass: "btn-icon-transparent px-0 search-toggle",
          on: { click: _vm.searchTogglefunc }
        },
        [_c("i", { staticClass: "icon-search fs20" })]
      ),
      _vm.searchToggle
        ? [
            _c(
              "div",
              {
                staticClass: "search-wrap",
                class: { "search-wrap--fullscreen": _vm.searchTerm }
              },
              [
                _c("b-form-input", {
                  staticClass: "search-box",
                  attrs: {
                    autofocus: true,
                    maxlength: "45",
                    autocomplete: "false",
                    trim: true,
                    placeholder: "- Type and hit enter -"
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getSearch($event)
                    }
                  },
                  model: {
                    value: _vm.searchTerm,
                    callback: function($$v) {
                      _vm.searchTerm = $$v
                    },
                    expression: "searchTerm"
                  }
                }),
                _c("div", { staticClass: "results-wrap" }, [
                  _vm.searchTerm
                    ? _c("ul", { staticClass: "search-results" }, [
                        _vm.results.key
                          ? _c("li", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "py-15 pl-0 pl-md-12 pr-8 align-items-center"
                                },
                                [
                                  _c("span", { staticClass: "results" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("uppercase")(_vm.search_title)
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _vm.url
                                    ? _c(
                                        "b-link",
                                        {
                                          staticClass: "float-right",
                                          attrs: { to: _vm.url }
                                        },
                                        [_vm._v(" Full Details ")]
                                      )
                                    : _c(
                                        "b-link",
                                        {
                                          staticClass:
                                            "remove-query float-right",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.removeTerm($event)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon-remove-circle fs14"
                                          })
                                        ]
                                      )
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        !_vm.results.key && !_vm.error && !_vm.isBusy
                          ? _c("div", { staticClass: "p-10" }, [
                              _c("p", { staticClass: "p-10 border-bottom" }, [
                                _c(
                                  "span",
                                  { staticClass: "text-gray-600 mr-5" },
                                  [_vm._v(_vm._s(_vm.selected.name))]
                                ),
                                _c("span", [_vm._v(_vm._s(_vm.searchTerm))]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "float-right text-gray-600 fs12 hidden-sm"
                                  },
                                  [_vm._v("Press Enter to search")]
                                )
                              ]),
                              _vm._m(0),
                              _vm._m(1),
                              _vm._m(2),
                              _vm._m(3),
                              _vm._m(4),
                              _vm._m(5)
                            ])
                          : _vm._e(),
                        _vm.isBusy
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "text-center text-secondary my-2 py-5"
                              },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle"
                                }),
                                _c("strong", [_vm._v("Loading...")])
                              ],
                              1
                            )
                          : _c("li", [
                              _vm.results.key == "shipment" &&
                              _vm.selected.id == "order"
                                ? _c(
                                    "div",
                                    _vm._l(_vm.results.result, function(item) {
                                      return _c(
                                        "li",
                                        { staticClass: "p-5 d-flex" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-100 col d-flex" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-5" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "mr-10 text-truncate"
                                                    },
                                                    [
                                                      _vm._m(6, true),
                                                      _vm._v(
                                                        _vm._s(
                                                          item.order.importedID
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "mr-10 text-truncate"
                                                    },
                                                    [
                                                      _vm._m(7, true),
                                                      _vm._v(
                                                        _vm._s(
                                                          item.order
                                                            .pickupDetails.name
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-5" },
                                                [
                                                  _c(
                                                    "p",
                                                    { staticClass: "ml-20" },
                                                    [
                                                      _c("strong", [
                                                        _vm._v("Shipment")
                                                      ]),
                                                      _vm._v(" - "),
                                                      _vm._v(
                                                        _vm._s(item.shipment.id)
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "ml-20 text-truncate"
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v("Customer Name")
                                                      ]),
                                                      _vm._v(" - "),
                                                      _vm._v(
                                                        _vm._s(
                                                          item.order.customer
                                                            .name
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-1 my-auto"
                                                },
                                                [
                                                  _c("router-link", {
                                                    staticClass:
                                                      "icon-share ml-10 mt-15",
                                                    attrs: {
                                                      to: item.url,
                                                      target: "_blank"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              _vm.results.key == "shipment" &&
                              _vm.selected.id != "order"
                                ? _c("div", [
                                    _c("div", { staticClass: "row p-10" }, [
                                      _c("div", { staticClass: "col-md-6" }, [
                                        _c("span", { staticClass: "px-10" }, [
                                          _vm._v("CUSTOMER DETAILS")
                                        ]),
                                        _c("table", [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-gray-600 text-right p-5"
                                              },
                                              [_vm._v("Name")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.results.result.order
                                                      .customer.name
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-gray-600 text-right p-5"
                                              },
                                              [_vm._v("Address")]
                                            ),
                                            _c("td", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.results.result.order
                                                    .customer.address
                                                )
                                              }
                                            })
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-gray-600 text-right p-5"
                                              },
                                              [_vm._v("Contact")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.results.result.order
                                                      .customer.contact
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ])
                                        ])
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 border-left" },
                                        [
                                          _c("span", { staticClass: "px-10" }, [
                                            _vm._v("ORDER DETAILS")
                                          ]),
                                          _c("table", [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-gray-600 text-right p-5"
                                                },
                                                [_vm._v("Warehouse")]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.results.result.order
                                                        .pickupDetails.name
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-gray-600 text-right p-5"
                                                },
                                                [_vm._v("Type")]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("uppercase")(
                                                        _vm.results.result.order
                                                          .type
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-gray-600 text-right p-5"
                                                },
                                                [_vm._v("Mode")]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$store.getters.modes.find(
                                                        function(i) {
                                                          return (
                                                            i.value ==
                                                            _vm.results.result
                                                              .shipment.mode
                                                          )
                                                        }
                                                      )
                                                        ? _vm.$store.getters.modes.find(
                                                            function(i) {
                                                              return (
                                                                i.value ==
                                                                _vm.results
                                                                  .result
                                                                  .shipment.mode
                                                              )
                                                            }
                                                          ).name
                                                        : "--"
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-gray-600 text-right p-5"
                                                },
                                                [_vm._v("Amount")]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.results.result.order
                                                          .totalValue,
                                                        "inr"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-gray-600 text-right p-5"
                                                },
                                                [_vm._v("Items")]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.results.result.order
                                                        .packageDetails.length
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ])
                                          ])
                                        ]
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm.results.key == "manifest"
                                ? _c(
                                    "div",
                                    [
                                      _c("b-table", {
                                        attrs: {
                                          hover: "",
                                          items: _vm.results.result,
                                          "thead-class": "d-none",
                                          fields: [
                                            "orderID",
                                            "mode",
                                            "processed",
                                            "awbNo",
                                            "created"
                                          ]
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.results.key == "customer" &&
                              _vm.results.type == "CustomerList"
                                ? _c(
                                    "div",
                                    _vm._l(_vm.results.result, function(item) {
                                      return _c(
                                        "li",
                                        { staticClass: "p-5 d-flex" },
                                        [
                                          _c("div", { staticClass: "w-100" }, [
                                            _c(
                                              "span",
                                              { staticClass: "mr-10" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("capitalize")(
                                                      item.name
                                                    )
                                                  ) + " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-gray-600 fs12"
                                              },
                                              [_vm._v(_vm._s(item.contact))]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "fs12 text-gray-600"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-20" },
                                                  [_vm._v("Shipment - ")]
                                                ),
                                                _vm._v(_vm._s(item.shipmentID))
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-gray-600 fs12"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.address) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]),
                                          item.trackingID
                                            ? _c("router-link", {
                                                staticClass:
                                                  "icon-share ml-10 mt-15",
                                                attrs: {
                                                  to:
                                                    "/shipments/" +
                                                    item.shipmentID,
                                                  target: "_blank"
                                                }
                                              })
                                            : _c("router-link", {
                                                staticClass:
                                                  "icon-share ml-10 mt-15",
                                                attrs: {
                                                  to: "/orders/" + item.orderID,
                                                  target: "_blank"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              _vm.results.key == "customer" &&
                              _vm.results.type == "CustomerCard"
                                ? _c("div", { staticClass: "col" }, [
                                    _c(
                                      "span",
                                      { staticClass: "px-10 pt-10 pt-md-10" },
                                      [_vm._v("CUSTOMER DETAILS")]
                                    ),
                                    _c(
                                      "table",
                                      { staticClass: "mt-10 ml-10" },
                                      [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-gray-600 p-5"
                                            },
                                            [_vm._v("Name")]
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.results.result.name
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-gray-600 p-5"
                                            },
                                            [_vm._v("Address")]
                                          ),
                                          _c("td", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.results.result.address
                                              )
                                            }
                                          })
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-gray-600 p-5"
                                            },
                                            [_vm._v("Contact")]
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.results.result.contact
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]),
                                        _vm.results.result.trackingID
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-gray-600 p-5"
                                                },
                                                [_vm._v("Shipment")]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.results.result
                                                        .shipmentID
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ])
                                          : _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-gray-600 p-5"
                                                },
                                                [_vm._v("Order")]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.results.result.orderID
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ])
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm.results.key == "awb"
                                ? _c(
                                    "div",
                                    [
                                      _c("b-table", {
                                        attrs: {
                                          hover: "",
                                          items: _vm.results.result.events,
                                          "thead-class": "d-none",
                                          fields: [
                                            "status",
                                            "remarks",
                                            "statusTime"
                                          ]
                                        },
                                        on: {
                                          "row-clicked": function($event) {
                                            return _vm.$router.push(
                                              "/shipments/" +
                                                _vm.item.shipmentID
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                        _vm.error
                          ? _c(
                              "li",
                              { staticClass: "py-10 text-red text-center" },
                              [_vm._v(" " + _vm._s(_vm.error) + " ")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c(
                    "ul",
                    { staticClass: "search-filters" },
                    _vm._l(_vm.searchFilters, function(searchFilter) {
                      return _c(
                        "li",
                        {
                          key: searchFilter.id,
                          staticClass: "cp",
                          class: {
                            "is-selected": searchFilter.id == _vm.selected.id
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "fs14 lh16",
                              on: {
                                click: function($event) {
                                  return _vm.toggleFilter(searchFilter)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(searchFilter.name) + " ")]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-10" }, [
      _c("b", [_vm._v("Order")]),
      _c("span", { staticClass: "text-gray-600 ml-20" }, [
        _vm._v("Search for order")
      ]),
      _c("span", { staticClass: "float-right" }, [
        _c("span", { staticClass: "text-gray-600" }, [_vm._v("E.g.")]),
        _vm._v(" 187**6")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-10" }, [
      _c("b", [_vm._v("Shipment")]),
      _c("span", { staticClass: "text-gray-600 ml-20" }, [
        _vm._v("Search for shipment")
      ]),
      _c("span", { staticClass: "float-right" }, [
        _c("span", { staticClass: "text-gray-600" }, [_vm._v("E.g.")]),
        _vm._v(" 123**0")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-10" }, [
      _c("b", [_vm._v("AWB")]),
      _c("span", { staticClass: "text-gray-600 ml-20" }, [
        _vm._v("Search for AWB")
      ]),
      _c("span", { staticClass: "float-right" }, [
        _c("span", { staticClass: "text-gray-600" }, [_vm._v("E.g.")]),
        _vm._v(" 7843551***86")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-10" }, [
      _c("b", [_vm._v("Manifest")]),
      _c("span", { staticClass: "text-gray-600 ml-20" }, [
        _vm._v("Search for manifests")
      ]),
      _c("span", { staticClass: "float-right" }, [
        _c("span", { staticClass: "text-gray-600" }, [_vm._v("E.g.")]),
        _vm._v(" 123**0")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row p-10" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _c("b", [_vm._v("Service")]),
        _c("span", { staticClass: "text-gray-600 ml-20" }, [
          _vm._v("Search for Service")
        ])
      ]),
      _c("span", { staticClass: "col-md-8 text-md-right d-block" }, [
        _c("span", { staticClass: "text-gray-600" }, [_vm._v("E.g.")]),
        _vm._v(
          " 411**6 (airPrepaid, airCod, airReverse, surfacePrepaid, surfaceCod, surfaceReverse, litePrepaid, liteCod, liteReverse)"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-10" }, [
      _c("b", [_vm._v("Customer")]),
      _c("span", { staticClass: "text-gray-600 ml-20" }, [
        _vm._v("Search for Customer")
      ]),
      _c("span", { staticClass: "float-right" }, [
        _c("span", { staticClass: "text-gray-600" }, [_vm._v("E.g.")]),
        _vm._v(" 982*****20")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ml-20" }, [
      _c("strong", [_vm._v("Order")]),
      _vm._v(" - ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ml-20" }, [
      _c("strong", [_vm._v("Warehouse")]),
      _vm._v(" - ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }