<template>
  <div class="container mw596">
    <div class="row pt-52 pt-md-32">
      <div class="col-2">
        <b-button @click="selectMethod('AddFundsMethod', 'back')" variant="xxs">
          <i class="icon-previous fs9 mr-10 text-gray-600"></i>
          <span class="fs12 lh14 fw500 wsnw"> Go back </span>
        </b-button>
      </div>
      <div class="col-8">
        <div class="d-flex flex-column align-items-center">
          <img src="@/assets/svg/neft.svg" alt="" />
          <span class="fs16 lh24 mt-24"> <strong>Submit Coupon Details</strong> </span>
        </div>
      </div>
    </div>

    <div class="row pt-32 pb-8">
      <div class="col">
        <b-form-group
          id="amount-field"
          label="Coupon Code"
          label-for="amount-value"
        >
          <b-input
            id="amount-value"
            placeholder="Enter Coupon Code"
            v-model="coupon"
          ></b-input>
        </b-form-group>
      </div>
    </div>

    <div class="row pt-0 pt-md-10 pb-0 pb-md-18">
      <div class="col text-right">
        <b-button
          @click="selectMethod('AddFundsSuccessfull', 'submit')"
          variant="primary"
          class="w-sm-100"
        >
          <span class="fw700"> Submit </span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddFundsCoupon",
  data() {
    return {
      coupon: "",
    };
  },
  methods: {
    async selectMethod(variable, path) {
      if (path == "back") {
        this.$emit("selectTab", variable);
      } else {
        try {
          const response = await this.axios.post("/redeemCoupon", {coupon: this.coupon});
          console.log(response);
          if (response.data.success) {
            this.popToast("booked", "Success!", response.data.success);
            this.$emit("selectTab", variable);
            this.$emit("updateAmount", response.data.amount);
          } else {
            this.popToast("failed", "Failed!",response.data.error);
          }
        } catch (error) {
          this.popToast(
            "failed",
            "Failed!",
            "Process Failed!! Please Try again Later"
          );
        }
      }
    },
  },
};
</script>
