    
  export default function longdate (value) {
      const nth = function(d) {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
      }

      const date = new Date(value);
      const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][date.getMonth()];
      let longdate = `${date.getDate()} ${month}, ${date.getFullYear()}`;
      return longdate
  }
