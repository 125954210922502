var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.clickOutsideMenu,
          expression: "clickOutsideMenu"
        }
      ],
      staticClass: "notifications"
    },
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle.sidebarNotifications",
              modifiers: { sidebarNotifications: true }
            }
          ],
          staticClass: "btn-icon-transparent px-0",
          class: { "has-notifications": _vm.$store.state.notifState },
          on: { click: _vm.bellUpdate }
        },
        [_c("i", { staticClass: "icon-notifications fs20" })]
      ),
      _vm.$store.state.global.notificationCount
        ? _c("span", { staticClass: "count" }, [
            _vm._v(_vm._s(_vm.$store.state.global.notificationCount))
          ])
        : _vm._e(),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "sidebarNotifications",
            right: "",
            "bg-variant": "white",
            "sidebar-class": "notifications-side",
            "no-header": "",
            shadow: "",
            title: "Sidebar"
          },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("div", { staticClass: "notifications-header" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center w-100 pb-28" },
              [
                _c("i", {
                  staticClass: "icon-notifications text-blue fs14 pr-12"
                }),
                _c("h2", { staticClass: "text-gray-900" }, [
                  _vm._v(" Notifications ")
                ]),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "rounded ml-auto" },
                    on: { click: _vm.goAllSettings }
                  },
                  [_c("i", { staticClass: "icon-settings fs14" })]
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.sidebarNotifications",
                        modifiers: { sidebarNotifications: true }
                      }
                    ],
                    attrs: { variant: "rounded ml-auto" },
                    on: {
                      click: function($event) {
                        return _vm.loadNotification(10)
                      }
                    }
                  },
                  [_c("i", { staticClass: "icon-close-variant fs14" })]
                )
              ],
              1
            )
          ]),
          _c(
            "ul",
            { staticClass: "notifications-list" },
            _vm._l(_vm.notifications, function(notification) {
              return _c("li", { key: notification.id }, [
                _c(
                  "div",
                  { staticClass: "d-flex w-100 align-items-center" },
                  [
                    notification.isRead == 0
                      ? [
                          _c("div", {
                            staticClass: "status status-unread mr-8"
                          })
                        ]
                      : [_c("div", { staticClass: "status status-read mr-8" })],
                    _c(
                      "span",
                      { staticClass: "fs16 lh20 fw500 text-gray-900" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("date")(notification.created)) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "span",
                      { staticClass: "ml-auto fs14 lh16 text-gray-400" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("time")(notification.created)) +
                            " "
                        )
                      ]
                    )
                  ],
                  2
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "fs14 lh18 pt-8 d-block text-gray-600 text-left"
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.notificationEllipsize(notification.text)) +
                        " "
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.fullNotification(notification)
                          }
                        }
                      },
                      [_vm._v(" More ")]
                    )
                  ]
                )
              ])
            }),
            0
          ),
          _vm.isMoreNotification
            ? _c(
                "div",
                { staticClass: "d-flex justify-content-center mt-10 mb-10" },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.loadNotification(
                            _vm.notifications.length + 10
                          )
                        }
                      }
                    },
                    [_vm._v("See more... ")]
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _vm.fullText
        ? _c("seeMoreDialog", {
            ref: "seeFullNotificationDialog",
            attrs: { fullText: _vm.fullText }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }