import Vue from "vue";
import App from "./App.vue";

import axios from "axios";
import money from 'v-money';
import Popper from 'popper.js';
import PortalVue from "portal-vue";
import VueShepherd from 'vue-shepherd';
import VueClipboard from 'vue-clipboard2';
import HighchartsVue from 'highcharts-vue';
import { BootstrapVue } from "bootstrap-vue";

import store from "./store";
import router from "./router";
import VueCookies from 'vue-cookies';

import "./assets/styles/styles.scss";
import filters from "./filters/index";

import toastMixin from './mixins/toastMixin';
import titleMixin from './mixins/titleMixin'; 
import ellipsize from './mixins/ellipsize'; 
import download from './mixins/download'; 
import common from './mixins/common'; 

import Hotjar from 'vue-hotjar'


// Hotjar configurations
Vue.use (Hotjar, {
  id: '2334076',
  isProduction: true,
  snippetVersion: 6
})

// Axios configurations
const headers = {
  // 'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
  // 'Access-Control-Allow-Headers': 'Content-Type',
  'Content-Type': 'application/json',
}

axios.interceptors.response.use(
  response => {
    if(store.state.user && store.state.user.sellerStatus && response.headers.agreementrequired) {
      store.commit('setGlobal', {'userAgreement': true})
    }
    if (response.headers.usermanagementapplicable && response.headers.usermanagementapplicable == 'true') {
      store.commit('setGlobal', {'showUsers': true})
    } else {
      store.commit('setGlobal', {'showUsers': false})
    }

    if (response.headers.customerbookapplicable && response.headers.customerbookapplicable == 'true') {
      store.commit('setGlobal', {'showCustomers': true})
    } else {
      store.commit('setGlobal', {'showCustomers': false})
    }

    if (response.headers.consignorapplicable && response.headers.consignorapplicable == 'true') {
      store.commit('setGlobal', {'showConsignorrequest': true})
    } else {
      store.commit('setGlobal', {'showConsignorrequest': false})
    }
    return response
  },
  error => {
    const {status} = error.response;
    if (status === 401) {
      store.dispatch('logout');
    } 
    if (status === 403) {
      store.dispatch('unauthorized')
    }
    if (status == 404) {
      store.dispatch('notFound')
    }
    return Promise.reject(error);
  }
  );

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers = headers
Vue.prototype.axios = axios

global.Popper = Popper;
Vue.config.productionTip = false;
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = true;

Vue.use(money);
Vue.use(filters);
Vue.use(PortalVue);
Vue.use(VueShepherd);
Vue.mixin(titleMixin);
Vue.mixin(toastMixin);
Vue.mixin(ellipsize);
Vue.mixin(download);
Vue.mixin(common);
Vue.use(VueCookies);
Vue.use(VueClipboard);
Vue.use(BootstrapVue);
Vue.use(HighchartsVue);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
