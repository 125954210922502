var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mw596" }, [
    _c("div", { staticClass: "row pt-52 pt-md-32" }, [
      _c(
        "div",
        { staticClass: "col-2" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "xxs" },
              on: {
                click: function($event) {
                  return _vm.selectMethod("AddFundsMethod", "back")
                }
              }
            },
            [
              _c("i", { staticClass: "icon-previous fs9 mr-10 text-gray-600" }),
              _c("span", { staticClass: "fs12 lh14 fw500 wsnw" }, [
                _vm._v(" Go back ")
              ])
            ]
          )
        ],
        1
      ),
      _vm._m(0)
    ]),
    _c("div", { staticClass: "row pt-32" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "amount-field",
                label: "Amount",
                "label-for": "amount-value"
              }
            },
            [
              _c(
                "b-input-group",
                { attrs: { append: "₹" } },
                [
                  _c("b-input", {
                    attrs: {
                      type: "number",
                      id: "amount-value",
                      placeholder: "0.00",
                      disabled: this.$store.state.rateAmount != ""
                    },
                    model: {
                      value: _vm.coupon,
                      callback: function($$v) {
                        _vm.coupon = $$v
                      },
                      expression: "coupon"
                    }
                  })
                ],
                1
              ),
              _c(
                "label",
                { staticClass: "fs12 lh14 text-gray-600 mt-38 mt-md-12 mw448" },
                [
                  _vm.planId
                    ? _c("span", [_vm._v("Amount")])
                    : _c("span", [_vm._v("Enter the amount")]),
                  _vm._v(
                    " of funds you are adding to the platform, all additional information will be handled through RazorPay. "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm.planId && this.$store.state.rateAmount < 200
      ? _c("div", { staticClass: "row col-md-12 mx-auto info-card" }, [
          _vm._v(
            " As the minimum recharge amount needs to be maintained at Rs.200, you are requested to recharge with Rs.200 to activate the selected plan. "
          )
        ])
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col pt-20 pt-md-20 pb-30 pb-md-30 text-right" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "razorpay", size: "lg" },
              on: {
                click: function($event) {
                  return _vm.selectMethod("AddFundsSuccessfull", "submit")
                }
              }
            },
            [
              _c("img", {
                staticClass: "mr-12",
                attrs: { src: require("@/assets/svg/razorpay.svg"), alt: "" }
              }),
              _c("span", [_vm._v(" Continue to RazorPay ")]),
              _c("i", { staticClass: "icon-expand-menu fs7 ml-20" })
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-8 my-15" }, [
      _c("div", { staticClass: "d-flex flex-column align-items-center" }, [
        _c("img", {
          staticStyle: { height: "3rem" },
          attrs: { src: require("@/assets/images/razorpay.png"), alt: "" }
        }),
        _c(
          "span",
          { staticClass: "fs16 lh24 mt-24 text-center text-md-left" },
          [_c("strong", [_vm._v("Submit Amount to be Added")])]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }