var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "seeFullNotificationDialog",
      attrs: {
        centered: "",
        title: "Full Notificatin",
        id: "seeFullNotificationDialog",
        "modal-class": "cancel-modal"
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "fs18 lh28 fw500" }, [
                _vm._v(" " + _vm._s(_vm.fullText.title) + " ")
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant fw400" })]
              )
            ]
          }
        },
        {
          key: "modal-footer",
          fn: function(ref) {
            var close = ref.close
            return [_vm._v(" " + _vm._s(_vm.fullText.text) + " ")]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [_c("div", { staticClass: "border_top" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }