export default {

	methods: {

		ellipsize(str, maxChars = 24) {

		    if (str && str.length > maxChars) {
		            str = str.substr(0, maxChars - 3) + "...";
		    }

		    return str

		},

		notificationEllipsize(str, maxChars = 80) {

		    if (str && str.length > maxChars) {
		            str = str.substr(0, maxChars - 3) + "...";
		    }

		    return str

		},

		imageEllipsize(str, maxChars = 15) {

		    if (str && str.length > maxChars) {
		            str = str.substr(0, maxChars - 3) + "...";
		    }

		    return str

		}
	}
}