<template>
  	<b-modal
	    centered
	    v-model="isOpen"
	    title="Full Notificatin"
	    id="seeFullNotificationDialog"
	    ref="seeFullNotificationDialog"
	    modal-class="cancel-modal"
  	>
	    <template v-slot:modal-header="{ close }">
	      	<span class="fs18 lh28 fw500">
	        	{{ fullText.title }}
	      	</span>
	      	<b-button variant="rounded" @click="close()">
	        	<i class="icon-close-variant fw400"></i>
	      	</b-button>
	    </template>
		<div class="border_top"></div>

	    <template v-slot:modal-footer="{ close }">
	    	{{ fullText.text }}
	    </template>
  </b-modal>
</template>

<script>
export default {
  	name: "seeFullNotificationDialog",
 	props: ["fullText"],
  	data() {
	    return {
	      isOpen: false,
	    };
  	},
  	methods: {
    	clearSelection: function(data) {
      		if (this.$parent.clearSelected) {
        		this.$parent.clearSelected();
      		}
      		this.isOpen = !this.isOpen;
    	},
  	},
};
</script>

<style lang="scss" scoped>
	.border_top {
		border-top: 2px solid #f5f7fa;
	}
</style>
