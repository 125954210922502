import uppercase from "./uppercase";
import lowercase from "./lowercase";
import initials from "./initials";
import capitalize from "./capitalize";
import date from "./date";
import time from "./time";
import currency from "./currency";
import longdate from "./longdate"
import logdate from "./logdate"
import hourtime from "./hourtime"
import trackingtime from "./trackingtime"



export default {
    install(Vue) {
        Vue.filter('uppercase' ,uppercase);
        Vue.filter('lowercase' ,lowercase);
        Vue.filter('initials' ,initials);
        Vue.filter('capitalize' ,capitalize);
        Vue.filter('date', date);
        Vue.filter('longdate', longdate);
        Vue.filter('logdate', logdate);
        Vue.filter('hourtime', hourtime);
        Vue.filter('time', time);
        Vue.filter('currency', currency)
        Vue.filter('trackingtime', trackingtime)
    }
}