var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mw596" }, [
    _c("div", { staticClass: "row pt-52 pt-md-32" }, [
      _c(
        "div",
        { staticClass: "col-2" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "xxs" },
              on: {
                click: function($event) {
                  return _vm.selectMethod("AddFundsMethod", "back")
                }
              }
            },
            [
              _c("i", { staticClass: "icon-previous fs9 mr-10 text-gray-600" }),
              _c("span", { staticClass: "fs12 lh14 fw500 wsnw" }, [
                _vm._v(" Go back ")
              ])
            ]
          )
        ],
        1
      ),
      _vm._m(0)
    ]),
    _c("div", { staticClass: "row pt-32 pb-8" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "amount-field",
                label: "Coupon Code",
                "label-for": "amount-value"
              }
            },
            [
              _c("b-input", {
                attrs: { id: "amount-value", placeholder: "Enter Coupon Code" },
                model: {
                  value: _vm.coupon,
                  callback: function($$v) {
                    _vm.coupon = $$v
                  },
                  expression: "coupon"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "row pt-0 pt-md-10 pb-0 pb-md-18" }, [
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "w-sm-100",
              attrs: { variant: "primary" },
              on: {
                click: function($event) {
                  return _vm.selectMethod("AddFundsSuccessfull", "submit")
                }
              }
            },
            [_c("span", { staticClass: "fw700" }, [_vm._v(" Submit ")])]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-8" }, [
      _c("div", { staticClass: "d-flex flex-column align-items-center" }, [
        _c("img", {
          attrs: { src: require("@/assets/svg/neft.svg"), alt: "" }
        }),
        _c("span", { staticClass: "fs16 lh24 mt-24" }, [
          _c("strong", [_vm._v("Submit Coupon Details")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }