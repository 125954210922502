<template>
  <div id="app">
    <AppSidebar v-if="!authPages" />
    <AppMobileMenu v-if="!authPages" />
    <div class="app-content" id="app-content" :class="{ auth: authPages }">
      <transition :name="transitionName">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import AppSidebar from "@/components/layout/AppSidebar.vue";
import AppMobileMenu from "@/components/layout/AppMobileMenu.vue";

const DEFAULT_TRANSITION = "slide";
const DEFAULT_TRANSITION_MODE = "slide-right";
export default {
  name: "App",
  title: "Shyplite",
  data() {
    return {
      transitionName: DEFAULT_TRANSITION,
      transitionMode: DEFAULT_TRANSITION_MODE,
      transitionEnterActiveClass: "",
    };
  },
  components: {
    AppSidebar,
    AppMobileMenu,
  },
  created() {
    // resize listener
    window.addEventListener("resize", this.$store.commit("setWindowWidth"));
    
    // transitions
    this.$router.beforeEach((to, from, next) => {
      let transitionName =
        to.meta.transitionName ||
        from.meta.transitionName ||
        DEFAULT_TRANSITION;

      if (transitionName === "slide") {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      }

      this.transitionMode = DEFAULT_TRANSITION_MODE;
      this.transitionEnterActiveClass = `${transitionName}-enter-active`;
      this.transitionName = transitionName;

      next();
    });
  },
  computed: {
    authPages() {
      const activeRoute = this.$route.fullPath;
      if (
        activeRoute.includes('login') ||
        activeRoute.includes('sign-up') ||
        activeRoute.includes('forgot-password') ||
        activeRoute.includes('sign-up-detail') ||
        activeRoute.indexOf("resetpassword")>=0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
