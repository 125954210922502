<template>
	<div class="search" :class="{ search__open: searchToggle }" v-click-outside="clickOutsideSearch">
		<b-button @click="searchTogglefunc" class="btn-icon-transparent px-0 search-toggle">
			<i class="icon-search fs20"></i>
		</b-button>

		<template v-if="searchToggle" >
			<div class="search-wrap" :class="{ 'search-wrap--fullscreen': searchTerm }">
				<b-form-input :autofocus="true" maxlength="45" autocomplete="false" @keyup.enter="getSearch" v-model="searchTerm" :trim="true" placeholder="- Type and hit enter -" class="search-box"></b-form-input>
				<div class="results-wrap">
					<ul class="search-results" v-if="searchTerm">
						<li v-if="results.key">
							<div class="py-15 pl-0 pl-md-12 pr-8 align-items-center">
								<span class="results">
									{{ search_title | uppercase }}
								</span>
								<b-link v-if="url" :to="url" class="float-right"> Full Details </b-link>
								<b-link v-else class="remove-query float-right" @click.prevent="removeTerm">
									<i class="icon-remove-circle fs14"></i>
								</b-link>
							</div>
						</li>
						<div v-if="!results.key && !error && !isBusy" class="p-10">
							<p class="p-10 border-bottom">
								<span class="text-gray-600 mr-5">{{ selected.name }}</span>
								<span>{{ searchTerm }}</span>
								<span class="float-right text-gray-600 fs12 hidden-sm">Press Enter to search</span>
							</p>
							<div class="p-10">
								<b>Order</b>
								<span class="text-gray-600 ml-20">Search for order</span>
								<span class="float-right"><span class="text-gray-600">E.g.</span> 187**6</span>
							</div>
							<div class="p-10">
								<b>Shipment</b>
								<span class="text-gray-600 ml-20">Search for shipment</span>
								<span class="float-right"><span class="text-gray-600">E.g.</span> 123**0</span>
							</div>
							<div class="p-10">
								<b>AWB</b>
								<span class="text-gray-600 ml-20">Search for AWB</span>
								<span class="float-right"><span class="text-gray-600">E.g.</span> 7843551***86</span>
							</div>
							<div class="p-10">
								<b>Manifest</b>
								<span class="text-gray-600 ml-20">Search for manifests</span>
								<span class="float-right"><span class="text-gray-600">E.g.</span> 123**0</span>
							</div>
							<div class="row p-10">
								<div class="col-md-4">
									<b>Service</b>
									<span class="text-gray-600 ml-20">Search for Service</span>
								</div>
								<span class="col-md-8 text-md-right d-block"><span class="text-gray-600">E.g.</span> 411**6 (airPrepaid, airCod, airReverse, surfacePrepaid, surfaceCod, surfaceReverse, litePrepaid, liteCod, liteReverse)</span>
							</div>
							<div class="p-10">
								<b>Customer</b>
								<span class="text-gray-600 ml-20">Search for Customer</span>
								<span class="float-right"><span class="text-gray-600">E.g.</span> 982*****20</span>
							</div>
						</div>
						<div v-if="isBusy" class="text-center text-secondary my-2 py-5">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Loading...</strong>
						</div>
						<li v-else>
							<div v-if="results.key == 'shipment' && selected.id == 'order' ">
								<li v-for="item in results.result" class="p-5 d-flex">
									<div class="w-100 col d-flex">
										<div class="col-md-5">
											<p class="mr-10 text-truncate"><span class="ml-20"><strong>Order</strong> - </span>{{ item.order.importedID }}</p>
											<p class="mr-10 text-truncate"><span class="ml-20"><strong>Warehouse</strong> - </span>{{ item.order.pickupDetails.name }}</p>
										</div>
										<div class="col-md-5">
											<p class="ml-20"><strong>Shipment</strong> - </span>{{ item.shipment.id }}</p>
											<p class="ml-20 text-truncate"><strong>Customer Name</strong> - </span>{{ item.order.customer.name }}</p>
										</div>
										<div class="col-md-1 my-auto">
											<router-link :to="item.url" target="_blank" class="icon-share ml-10 mt-15"></router-link>
										</div>
									</div>
								</li>
							</div>
							<div v-if="results.key == 'shipment' && selected.id != 'order'">
								<div class="row p-10">
									<div class="col-md-6">
										<span class="px-10">CUSTOMER DETAILS</span>
										<table>
											<tr><td class="text-gray-600 text-right p-5">Name</td><td> {{ results.result.order.customer.name }} </td></tr>
											<tr><td class="text-gray-600 text-right p-5">Address</td><td v-html="results.result.order.customer.address"></td></tr>
											<tr><td class="text-gray-600 text-right p-5">Contact</td><td> {{ results.result.order.customer.contact }} </td></tr>
										</table>
									</div>
									<div class="col-md-6 border-left">
										<span class="px-10">ORDER DETAILS</span>
										<table>
											<tr><td class="text-gray-600 text-right p-5">Warehouse</td><td> {{ results.result.order.pickupDetails.name }} </td></tr>
											<tr><td class="text-gray-600 text-right p-5">Type</td><td> {{ results.result.order.type | uppercase }} </td></tr>
											<tr><td class="text-gray-600 text-right p-5">Mode</td><td> {{ $store.getters.modes.find(i => i.value == results.result.shipment.mode)?$store.getters.modes.find(i => i.value == results.result.shipment.mode).name:'--' }} </td></tr>
											<tr><td class="text-gray-600 text-right p-5">Amount</td><td> {{ results.result.order.totalValue | currency("inr") }} </td></tr>
											<tr><td class="text-gray-600 text-right p-5">Items</td><td> {{ results.result.order.packageDetails.length }} </td></tr>
										</table>
									</div>
								</div>
							</div>
							<div v-if="results.key == 'manifest'">
								<b-table hover :items="results.result" thead-class="d-none" :fields="['orderID', 'mode', 'processed', 'awbNo', 'created']"></b-table>
							</div>
							<div v-if="results.key == 'customer' && results.type == 'CustomerList'">
								<li v-for="item in results.result" class="p-5 d-flex">
									<div class="w-100">
										<span class="mr-10">{{ item.name | capitalize }} </span>
										<span class="text-gray-600 fs12">{{ item.contact }}</span>
										<span class="fs12 text-gray-600"><span class="ml-20">Shipment - </span>{{ item.shipmentID }}</span>
										<p class="text-gray-600 fs12"> {{ item.address }} </p>
									</div>
									<router-link v-if="item.trackingID" :to="'/shipments/'+item.shipmentID" target="_blank" class="icon-share ml-10 mt-15"></router-link>
									<router-link v-else :to="'/orders/'+item.orderID" target="_blank" class="icon-share ml-10 mt-15"></router-link>
								</li>
							</div>
							<div class="col" v-if="results.key == 'customer' && results.type == 'CustomerCard'">
								<span class="px-10 pt-10 pt-md-10">CUSTOMER DETAILS</span>
								<table class="mt-10 ml-10">
									<tr><td class="text-gray-600 p-5">Name</td><td> {{ results.result.name }} </td></tr>
									<tr><td class="text-gray-600 p-5">Address</td><td v-html="results.result.address"></td></tr>
									<tr><td class="text-gray-600 p-5">Contact</td><td> {{ results.result.contact }} </td></tr>
									<tr v-if="results.result.trackingID"><td class="text-gray-600 p-5" >Shipment</td><td> {{ results.result.shipmentID }} </td></tr>
									<tr v-else><td class="text-gray-600 p-5">Order</td><td> {{ results.result.orderID }} </td></tr>
								</table>
							</div>
							<!-- <div v-if="results.key == 'service'">
								<li v-for="(item, index) in results.result.warehouses" class="p-5">
									{{ index }}
								</li>
							</div> -->
							<div v-if="results.key == 'awb'">
								<b-table hover :items="results.result.events" thead-class="d-none" @row-clicked="$router.push('/shipments/'+item.shipmentID)" :fields="['status', 'remarks', 'statusTime']"></b-table>
							</div>
						</li>
						<li v-if="error" class="py-10 text-red text-center">
							{{ error }}
						</li>
					</ul>
					<ul class="search-filters">
						<li v-for="searchFilter in searchFilters" :key="searchFilter.id" class="cp" :class="{ 'is-selected': searchFilter.id == selected.id }">
							<span @click="toggleFilter(searchFilter)" class="fs14 lh16">
								{{ searchFilter.name }}
							</span>
						</li>
					</ul>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
	name: "Search",
	data() {
		return {
			searchToggle 	: window.screen.width > 767 ? true : false,
			searchTerm 		: '',
			isBusy 			: false,
			error 			: '',
			url 			: '',
			selectedFilters : [],
			selected 		: { id: 'order', name: "Order ID", isActive: true },
			results 		: {},
			search_title 	: 'Results',
			status 			: [],
			searchFilters 	: [
			{
				id: 'order',
				name: "Order ID",
				isActive: true,
			},
			{
				id: 'shipment',
				name: "Shipment ID",
				isActive: false,
			},
			{
				id: 'awb',
				name: "AWB",
				isActive: false,
			},
			{
				id: 'manifest',
				name: "Manifest ID",
				isActive: false,
			},
			{
				id: 'customer',
				name: "Customer",
				isActive: false,
			},
			],
		};
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	methods: {
		clickOutsideSearch() {
			if (window.screen.width < 767) {
				this.searchToggle = false;
			}
		},
		toggleFilter(filter) {
			this.selected = filter
			this.results = {}
		},
		removeTerm() {
			this.searchTerm = "";
		},
		searchTogglefunc() {
			this.searchToggle = !this.searchToggle
			this.searchTerm = null
		},
		async getSearch() {
			try {
				this.isBusy = true
				this.error = ''
				let search = {}
				this.results = {}
				this.getStatus()
				if((this.selected.id == 'customer' || this.selected.id == 'order') && this.searchTerm.length < 3) {
					this.error = 'Minimum 3 characters are required.'
				}else{
					this.error = ''
					search[this.selected.id] = this.searchTerm
					const res = await this.axios.post('/v3/search', search)
					if(res.data.success === false) {
						this.error = res.data.message
						this.results = {}
					}else {
						this.results = res.data
						if(res.data.key == 'shipment' && this.selected.id == 'order') {
							res.data.result.map(i => {
								if (i.shipment.trackingID == null) {
									i['url'] = '/orders/'+i.shipment.orderID
								} else {
									i['url'] = '/shipments/'+i.shipment.id
								}
							})
						}else if(this.selected.id == 'shipment' || this.selected.id == 'awb') {
							if (res.data && res.data.shipment.trackingID == null) {
								this.url = '/orders/'+res.data.shipment.orderID
							} else {
								this.url = '/shipments/'+res.data.shipment.id
							}	
						} else if (res.data.key == 'customer' && res.data.type == "CustomerCard") {
							if (res.data.result.trackingID == null) {
								this.url = '/orders/'+res.data.result.orderID
							} else {
								this.url = '/shipments/'+res.data.result.shipmentID
							}
						}
						else if(res.data.key == 'manifest') {
							this.url = `/shipments?manifestID=${this.searchTerm}`
						}else {
							this.url = ''
						}
						this.search_title = this.selected.name + ': ' + this.searchTerm
						if (!this.results.hasOwnProperty('result')) {
							this.results['result'] = res.data
						}
						if (!this.results.hasOwnProperty('key')) {
							this.results['key'] = "shipment"
						} 
					}
				}
				this.isBusy = false
			} catch(e) {
				console.log(e);
			}
		},

		async getStatus() {
			const res = await this.axios.get('/statics/statuses')
			this.status = res.data.forwardStatus
		}
	},
	computed: {
		windowWidth() {
			return window.screen.width;
		},
	},
};
</script>

<style lang="scss" scoped>
.search {
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	width: 100%;
	z-index: 99;
	&-toggle {
		position: absolute;
		right: 0;
		z-index: 9;
	}
	&-wrap {
		display: flex;
		width: 100%;
		height: rem(44px);
		transition: all 0.3s ease-in;
		margin-right: rem(-4px);
		box-shadow: $box-shadow-dark;

		&--fullscreen {
			height: calc(100% - 75px) !important;
		}

		.search-box {
			width: 100%;
			min-width: rem(120px);
			max-width: rem(380px);
			padding-left: rem(20px);
			margin-right: rem(4px);
			background-color: $white !important;
			border-radius: rem(8px) 0 0 rem(8px) !important;
		}

		.search-filters {
			display: flex;
			align-items: center;
			margin: 0;
			padding: 0;
			list-style-type: none;
			background-color: $white;
			border-top-right-radius: rem(8px);
			border-bottom-right-radius: rem(8px);
			.btn-icon-transparent {
				z-index: 99;
			}
			li {
				font-weight: 500;
				color: $gray-600;
				white-space: nowrap;
				margin: 0 rem(12px);
				padding: rem(12px) 0;
				border-bottom: 2px solid transparent;
				@include transition;
				&:first-child {
					margin: 0 rem(14px) 0 rem(20px);
				}
				&:last-child {
					margin: 0 rem(58px) 0 rem(14px);
				}
				span {
					@include transition;
				}
				@include media-breakpoint-down(md) {
					margin: 0 rem(6px);
					&:first-child {
						margin: 0 rem(6px);
					}
					&:last-child {
						margin: 0 rem(46px) 0 rem(6px);
					}
				}
			}
		}

		.results-wrap {
			margin-right: rem(-3px);
		}

		.filters-dropdown {
			display: none;
			max-height: rem(44px);

			z-index: 99;
			.multiselect__tags {
				height: rem(44px);
				overflow: scroll;
				background-color: $white !important;
				border-radius: 0 8px 8px 0 !important;
				padding: rem(14px) rem(28px) rem(14px) rem(16px) !important;
			}
			.multiselect__tag {
				color: $gray-900;
				background: $gray-200;
				&-icon {
					&::after {
						color: $gray-900;
					}
					&:hover,
					&:focus,
					&:active {
						background-color: $gray-400;
					}
				}
			}
			@include media-breakpoint-down(lg) {
				display: block;
			}
		}

		.search-results {
			position: absolute;
			margin: 0;
			padding: 0;
			top: rem(46px);
			left: rem(3px);
			width: calc(100% + 7px);
			height: rem(227px);
			overflow-y: scroll;
			list-style-type: none;
			border-radius: $border-radius;
			background-color: $white;
			box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
			.results {
				font-size: rem(10px);
				line-height: rem(12px);
				color: $gray-600;
				font-weight: 700;
			}
			li {
				font-size: rem(13px);
				line-height: rem(20px);
				border-top: 1px solid $gray-100;
				margin: 0 rem(5px);

				.result-item {
					position: relative;
					display: inline-flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					text-decoration: none;
					padding: rem(20px) 0 rem(21px) 0;
					.title {
						font-size: rem(13px);
						line-height: rem(20px);
						color: $gray-900;
						margin-right: rem(12px);
					}
					.status {
						font-size: rem(11px);
						line-height: rem(20px);
						color: $gray-600;
						margin-right: rem(12px);
					}
					.meta {
						font-size: rem(11px);
						line-height: rem(20px);
						color: $gray-600;
					}
					i {
						color: $gray-600;
						@include transition;
					}

					&:hover,
					&:focus,
					&:active {
						i {
							color: $blue;
						}
					}
					&:before {
						display: inline-flex;
						content: "";
						width: rem(4px);
						height: rem(4px);
						margin-left: rem(12px);
						margin-right: rem(8px);
						background-color: $blue;
						border-radius: 50%;
					}

					@include media-breakpoint-down(lg) {
						flex-direction: column;
						align-items: flex-start;
						padding: 1rem;
						i {
							position: absolute;
							top: calc(50% - 6px);
							right: rem(8px);
						}
						&:before {
							margin-left: 2px;
							margin-bottom: rem(12px);
						}
					}
				}

				.remove-query {
					color: $gray-600;
					display: inline;
					margin-left: auto;
					text-decoration: none;
					i {
						vertical-align: middle;
					}
					&:hover,
					&:focus,
					&:active {
						color: $red;
					}
				}
			}
		}
	}
	&__open {
		.search-toggle {
			position: absolute;
			color: $blue;
		}
	}

	.is-selected {
		border-bottom: 2px solid $blue !important;
		span {
			color: $blue;
		}
	}

	@include media-breakpoint-down(sm) {
		&-wrap {
			position: fixed;
			display: flex;
			flex-direction: column;
			height: auto;
			top: rem(76px);
			right: 0;
			left: 0;
			background-color: $white;
			&:before {
				display: block;
				content: "";
				position: fixed;
				top: rem(240px);
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba($gray-900, 0.7);
				z-index: -1;
			}
			.search-box {
				position: relative;
				display: block;
				width: calc(100vw - 40px);
				margin: rem(24px) rem(20px) rem(14px) rem(20px);
				background-color: $gray-100 !important;
				border-radius: $border-radius-sm !important;
			}
			.results-wrap {
				display: flex;
				flex-direction: column-reverse;
				margin-right: 0;
				.search-filters {
					position: relative;
					display: flex;
					flex-wrap: wrap;
					padding: rem(3px) rem(20px) rem(20px) rem(20px);
					margin: 0 -8px;
					li {
						margin: rem(9px) rem(8px);
						padding: 0;
						span {
							display: block;
							color: $blue;
							font-variant: small-caps;
							line-height: rem(20px);
							padding: rem(10px) rem(20px);
							box-shadow: $box-shadow-dark;
							border-radius: rem(10px);
						}
					}
					.is-selected {
						border-bottom: 2px solid transparent !important;
						span {
							color: $white;
							background-color: $blue;
						}
					}
				}
				.search-results {
					position: relative;
					top: 0;
					left: 0;
					box-shadow: none;
					border-top: 1px solid $gray-200;
					border-radius: 0;
					max-height: rem(308px);
					.remove-query {
						display: none;
					}
					li {
						margin: 0 rem(20px);
						&:first-child {
							border-top: 0px;
						}
						&:nth-child(2) {
							border-top: 0px;
						}
						.result-item {
							padding: 1rem 0;
							.title {
								margin-left: rem(16px);
							}
							&:before {
								position: absolute;
								top: 23px;
							}
						}
					}
				}
			}
		}
	}
}
</style>