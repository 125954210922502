import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from '../router/index';
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: true, encodingType: 'rc4', encryptionSecret: process.env.VUE_APP_ENC_KEY });

Vue.use(Vuex);

export default new Vuex.Store({

    state: {

        windowWidth: window.innerWidth,
        user: {},
        filters: {},
        chartdata: {},
        global: {},
        notifState:null,
        sellerState:false,
        kycInfo: {},
        tab: 0,
        due:null,
        pageOptions: [25, 50, 100],
        perPage: 25,
        maxD:"999",
        minD:"1",
        minW:"0.01",
        maxW:"999.99",
        maxI:"9999999",
        apiCredentials:{},
        errorButton:false,
        rateAmount:'',
        notifications: [],
        customerObj:{},

        singleOrder: {
            order: {
                selected: false,
                orderId: "XYXX4215_XYXX29353",
                platform: "Shopify",
                date: "04/09/2020",
                customerName: "Sarvhesh Vaidyam",
                customerMeta: "201301 - Mota dahisara",
                value: 627.64,
                type: "ppd",
                warehouseName: "Chitrachaya",
                warehousePostcode: 411006,
                quantity: "1/4",
                length: 10,
                width: 10,
                height: 10,
                weight: 22.5,
                mode: "Surface 10kg",
                carrier: "Dhelivery",
                awb: "3731718065425",
                status: "Unprocessed",
            },
            shippingStages: [
                {
                    status: "Shipment Initiated",
                    location: "Dwarka",
                    dateTime: "31-07-2020, 05:06 AM",
                    meta: "Received at Fkl-Sankrail",
                },
                {
                    status: "Picked Up",
                    location: "Kolkata",
                    dateTime: "31-07-2020, 05:06 AM",
                    meta: "shipment_pickup_complete",
                },
                {
                    status: "Shipment Booked",
                    location: "Dwarka",
                    dateTime: "31-07-2020, 05:06 AM",
                    meta: "Expected at EKL_Bamnoli",
                },
                {
                    status: "In Transit",
                    location: "Kolkata",
                    dateTime: "01-08-2020, 09:21 PM",
                    meta: "Received at Fkl-Sankrail",
                }
            ],
            tableItems: [
                {
                    category: "Books",
                    name: "CA Books",
                    dimensions: { width: "12", height: "8", length: "4" },
                    weight: "0.03kg",
                    quantity: 4,
                    value: 5000.0,
                },
                {
                    category: "Books",
                    name: "Counter Print Books",
                    dimensions: { width: "12", height: "8", length: "4" },
                    weight: "4.20kg",
                    quantity: 4,
                    value: 24020.0,
                },
            ],
            tableFields: [
                {
                    key: "category",
                    sortable: true,
                },
                {
                    key: "name",
                    sortable: true,
                },
                { key: "dimensions", label: "Dimensions" },
                {
                    key: "weight",
                    sortable: true,
                },
                {
                    key: "quantity",
                    sortable: true,
                },
                {
                    key: "value",
                    sortable: true,
                },
            ],
        },

        shipmentItems: [],

        billingInvoicesItems: [],

        billingTransactionItems: [],

        manifestsItems: [],

        ndrItems: [],

        codItems: [],

        shipmentEditItems: [
            {
                selected: false,
                sku: "TGJFIAL",
                name: "CA Books",
                dimensions: { width: "12", height: "8", length: "4" },
                weight: "0.03kg",
                quantity: 4,
                value: 5000.0,
            },
            {
                selected: true,
                sku: "TGJFIAL",
                name: "CA Books",
                dimensions: { width: "12", height: "8", length: "4" },
                weight: "0.03kg",
                quantity: 4,
                value: 5000.0,
            },
            {
                selected: false,
                sku: "TGJFIAL",
                name: "CA Books",
                dimensions: { width: "12", height: "8", length: "4" },
                weight: "0.03kg",
                quantity: 4,
                value: 5000.0,
            },
        ],

        ticketUpdates: [
            {
                id: 241,
                meta: { title: "Issue with Delivery", status: "open" },
                awb: "126318028397",
                shipment: "4485879865",
                time: "09:21 PM",
                unreadMessages: "2",
            },
            {
                id: 201,
                meta: { title: "AWB not assigned", status: "open" },
                awb: null,
                shipment: "4485879865",
                time: "09:21 PM",
                unreadMessages: "2",
            },
            {
                id: 221,
                meta: { title: "Status of some DZ, DL and XB shipments showing Delivered", status: "closed" },
                awb: "126318028397",
                shipment: "4485879865",
                time: "09:21 PM",
                unreadMessages: "2",
            },
            {
                id: 231,
                meta: { title: "Issue with Delivery", status: "closed" },
                awb: "126318028397",
                shipment: "4485879865",
                time: "09:21 PM",
                unreadMessages: "2",
            },
            {
                id: 202,
                meta: { title: "AWB not assigned", status: "open" },
                awb: "126318028397",
                shipment: "4485879865",
                time: "09:21 PM",
                unreadMessages: "2",
            },
            {
                id: 203,
                meta: { title: "Issue with Delivery", status: "open" },
                awb: "126318028397",
                shipment: "4485879865",
                time: "09:21 PM",
                unreadMessages: "2",
            }
        ],

        connectors: [
            {
                id: 0,
                status: false,
                img: require("@/assets/svg/shopify-logo.svg"),
                title: "Shopify",
                subtitle: "Name of the shop",
                modalName: "shopify-modal",
            },
            {
                id: 1,
                status: true,
                img: require("@/assets/svg/amazon-logo.svg"),
                title: "Amazon",
                subtitle: "Name of the shop",
                modalName: "amazon-modal",
            },
            {
                id: 2,
                status: false,
                img: require("@/assets/svg/opencart-logo.svg"),
                title: "OpenCart",
                subtitle: "Name of the shop",
                modalName: "opencart-modal",
            },
            {
                id: 3,
                status: false,
                img: require("@/assets/svg/magento2-logo.svg"),
                title: "Magento 2.X",
                subtitle: "Name of the shop",
                modalName: "magento-modal",
            },
            {
                id: 4,
                status: false,
                img: require("@/assets/svg/WooCommerce-logo.svg"),
                title: "WooCommerce",
                subtitle: "Name of the shop",
                modalName: "woocommerce-modal",
            },
            {
                id: 5,
                status: false,
                img: require("@/assets/svg/flipkart-logo.svg"),
                title: "Flipkart",
                subtitle: "Name of the shop",
                modalName: "flipkart-modal",
            },
            {
                id: 6,
                status: false,
                img: require("@/assets/svg/zoho-logo.svg"),
                title: "Zoho",
                subtitle: "Name of the shop",
                modalName: "zoho-modal",
            }
        ],

        warehouses: [],
        consignors: [],
        modes: [],
        mode: [],
        carriers: [],
        channels: [],

        charts: [
            {
                id: "Shipments",
                title: "Shipments",
                height: {
                    minHeight: "240px",
                    maxHeight: "240px"
                },
                chartData: [
                    {
                        name: "Delivered",
                        y: 18.41,
                        color: "#64d778",
                    },
                    {
                        name: "Cancelled",
                        y: 6.84,
                        color: "#fa5a5a",
                    },
                    {
                        name: "RTO - Delivered",
                        y: 10.85,
                        color: "#a064e6",
                    },
                    {
                        name: "RTO - In Transit",
                        y: 4.67,
                        color: "#ffcd3c",
                    },
                    {
                        name: "Lost",
                        y: 8.18,
                        color: "#e5f0ff",
                    },
                ],
            },
            {
                id: "OrderType",
                title: "Order Type",
                height: {
                    minHeight: "240px",
                    maxHeight: "240px"
                },
                categories: ["01/06", "03/06", "05/06", "07/06", "09/06", "11/06"],
                chartData: [
                    {
                        name: "Prepaid",
                        data: [26, 89, 62, 57.2, 44.0, 99.0],
                        color: "#006eff",
                    },
                    {
                        name: "COD",
                        data: [26, 74, 62, 93.4, 76.0, 74.5],
                        color: "#e5f0ff",
                    },
                ],
            },
            {
                id: "ShipmentsStacked",
                title: "Shipments",
                height: {
                    minHeight: "400px",
                    maxHeight: "600px"
                },
                categories: [
                    "Maharashtra",
                    "Andhra Pradesh",
                    "West Bengal",
                    "Uttar Pradesh",
                    "Karnataka",
                    "Assam",
                    "Delhi",
                    "Bihar",
                    "Telangana",
                    "Madhya Pradesh",
                ],
                chartData: [
                    {
                        name: "COD",
                        data: [4, 32, 36, 36, 42, 3, 53, 8, 25],
                        color: "#ffcd3c",
                    },
                    {
                        name: "Prepaid",
                        data: [96, 59, 54, 56, 42, 71, 12, 64, 47],
                        color: "#64d778",
                    },
                ]
            },
            {
                id: "Carriers",
                title: "Carriers",
                height: {
                    minHeight: "200px",
                    maxHeight: "200px"
                },
                chartData: [
                    {
                        name: "BlueDart",
                        data: [26, 89, 62, 57.2, 44.0],
                        color: "#64d778",
                    },
                    {
                        name: "Delhivery",
                        data: [66, 74, 53, 93.4, 89.0],
                        color: "#fa5a5a",
                    },
                    {
                        name: "Fedex",
                        data: [12, 34, 82, 93.4, 11.0],
                        color: "#a064e6",
                    },
                    {
                        name: "Xpressbees",
                        data: [87, 12, 12, 93.4, 23.0],
                        color: "#ffcd3c",
                    }
                ],
                categories: [
                    "Metro to Metro",
                    "Rest of India",
                    "Special",
                    "Within City",
                    "Within Zone"
                ],
            }
        ],

        supportTickets: {
            activeTickets: [
                {
                    id: 241,
                    unread: false,
                    title: "Issue with Delivery",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: [
                        {
                            id: 0,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/img/user2.jpg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "The shipment with the AWB 126318028397 didn’t arrive still to this day, what can we do?",
                            attachment: null,
                            unread: true,
                        },
                        {
                            id: 2,
                            sender: "Soni Shukla",
                            img: require("@/assets/img/user.png"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Hi manikandan, The weight dispute is registered for the amount of Rs 1540/- under the Invoice -SL011763 and it is currently under investigation with us. Kindly hold the disputed amount and release the non-disputed payment to clear the Invoice. Please feel free to reach us in case of any further queries. Regards, Soni Shukla Team Shyplite +91-9643318580",
                            attachment: null,
                            unread: false,
                        },
                        {
                            id: 3,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/img/user2.jpg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Thanks for the response, i’m attaching a ledger pdf file to this message so feel free to review that and let me know what you think.",
                            attachment: null,
                            unread: false,
                        },
                        {
                            id: 4,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/img/user2.jpg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Thanks for the response, i’m attaching a ledger pdf file to this message so feel free to review that and let me know what you think.",
                            attachment: null,
                            unread: false,
                        },
                        {
                            id: 5,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/img/user2.jpg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Thanks for the response, i’m attaching a ledger pdf file to this message so feel free to review that and let me know what you think.",
                            attachment: require("@/assets/svg/fedex_logo.svg"),
                            unread: false,
                        }
                    ]
                },
                {
                    id: 221,
                    unread: true,
                    title: "Status of some DZ, DL and XB shipments showing Delivered",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: [
                        {
                            id: 0,
                            sender: "Harpreet Mhatoa",
                            img: require("@/assets/svg/delhivery-icon.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "The shipment with the AWB 126318028397 didn’t arrive still to this day, what can we do?",
                            attachment: null,
                            unread: true,
                        },
                        {
                            id: 2,
                            sender: "Soni Shukla",
                            img: require("@/assets/svg/fedex_logo.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Hi manikandan, The weight dispute is registered for the amount of Rs 1540/- under the Invoice -SL011763 and it is currently under investigation with us. Kindly hold the disputed amount and release the non-disputed payment to clear the Invoice. Please feel free to reach us in case of any further queries. Regards, Soni Shukla Team Shyplite +91-9643318580",
                            attachment: null,
                            unread: true,
                        },
                        {
                            id: 3,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/svg/delhivery-icon.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Thanks for the response, i’m attaching a ledger pdf file to this message so feel free to review that and let me know what you think.",
                            attachment: require("@/assets/svg/fedex_logo.svg"),
                            unread: false,
                        }
                    ]
                },
                {
                    id: 204,
                    unread: false,
                    title: "Issue with Delivery",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: [
                        {
                            id: 0,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/svg/delhivery-icon.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "The shipment with the AWB 126318028397 didn’t arrive still to this day, what can we do?",
                            attachment: null,
                            unread: true,
                        },
                        {
                            id: 2,
                            sender: "Soni Shukla",
                            img: require("@/assets/svg/fedex_logo.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Hi manikandan, The weight dispute is registered for the amount of Rs 1540/- under the Invoice -SL011763 and it is currently under investigation with us. Kindly hold the disputed amount and release the non-disputed payment to clear the Invoice. Please feel free to reach us in case of any further queries. Regards, Soni Shukla Team Shyplite +91-9643318580",
                            attachment: null,
                            unread: false,
                        },
                        {
                            id: 3,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/svg/delhivery-icon.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Thanks for the response, i’m attaching a ledger pdf file to this message so feel free to review that and let me know what you think.",
                            attachment: require("@/assets/svg/fedex_logo.svg"),
                            unread: false,
                        }
                    ]
                },
                {
                    id: 203,
                    unread: false,
                    title: "Status of some DZ, DL and XB shipments showing Delivered",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: [
                        {
                            id: 0,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/svg/delhivery-icon.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "The shipment with the AWB 126318028397 didn’t arrive still to this day, what can we do?",
                            attachment: null,
                            unread: true,
                        },
                        {
                            id: 2,
                            sender: "Soni Shukla",
                            img: require("@/assets/svg/fedex_logo.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Hi manikandan, The weight dispute is registered for the amount of Rs 1540/- under the Invoice -SL011763 and it is currently under investigation with us. Kindly hold the disputed amount and release the non-disputed payment to clear the Invoice. Please feel free to reach us in case of any further queries. Regards, Soni Shukla Team Shyplite +91-9643318580",
                            attachment: null,
                            unread: true,
                        },
                        {
                            id: 3,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/svg/delhivery-icon.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Thanks for the response, i’m attaching a ledger pdf file to this message so feel free to review that and let me know what you think.",
                            attachment: require("@/assets/svg/fedex_logo.svg"),
                            unread: false,
                        }
                    ]
                },
                {
                    id: 202,
                    unread: true,
                    title: "Issue with Delivery",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: [
                        {
                            id: 0,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/svg/delhivery-icon.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "The shipment with the AWB 126318028397 didn’t arrive still to this day, what can we do?",
                            attachment: null,
                            unread: true,
                        },
                        {
                            id: 2,
                            sender: "Soni Shukla",
                            img: require("@/assets/svg/fedex_logo.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Hi manikandan, The weight dispute is registered for the amount of Rs 1540/- under the Invoice -SL011763 and it is currently under investigation with us. Kindly hold the disputed amount and release the non-disputed payment to clear the Invoice. Please feel free to reach us in case of any further queries. Regards, Soni Shukla Team Shyplite +91-9643318580",
                            attachment: null,
                            unread: false,
                        },
                        {
                            id: 3,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/svg/delhivery-icon.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Thanks for the response, i’m attaching a ledger pdf file to this message so feel free to review that and let me know what you think.",
                            attachment: require("@/assets/svg/fedex_logo.svg"),
                            unread: false,
                        }
                    ]
                },
                {
                    id: 201,
                    unread: false,
                    title: "Last Status of some DZ, DL and XB shipments showing Delivered",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: [
                        {
                            id: 0,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/svg/delhivery-icon.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "The shipment with the AWB 126318028397 didn’t arrive still to this day, what can we do?",
                            attachment: null,
                            unread: true,
                        },
                        {
                            id: 2,
                            sender: "Soni Shukla",
                            img: require("@/assets/svg/fedex_logo.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Hi manikandan, The weight dispute is registered for the amount of Rs 1540/- under the Invoice -SL011763 and it is currently under investigation with us. Kindly hold the disputed amount and release the non-disputed payment to clear the Invoice. Please feel free to reach us in case of any further queries. Regards, Soni Shukla Team Shyplite +91-9643318580",
                            attachment: null,
                            unread: true,
                        },
                        {
                            id: 3,
                            sender: "Harpreet Bhatoa",
                            img: require("@/assets/svg/delhivery-icon.svg"),
                            dateTime: "04/09/2020, 09:20 AM",
                            message: "Thanks for the response, i’m attaching a ledger pdf file to this message so feel free to review that and let me know what you think.",
                            attachment: require("@/assets/svg/fedex_logo.svg"),
                            unread: false,
                        }
                    ]
                }
            ],
            closedTickets: [
                {
                    id: 101,
                    title: "Status of some DZ, DL and XB shipments showing Delivered",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: null
                },
                {
                    id: 102,
                    title: "Status of some DZ, DL and XB shipments showing Delivered",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: null
                },
                {
                    id: 103,
                    title: "Status of some DZ, DL and XB shipments showing Delivered",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: null
                },
                {
                    id: 104,
                    title: "Status of some DZ, DL and XB shipments showing Delivered",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: null
                },
                {
                    id: 105,
                    title: "Status of some DZ, DL and XB shipments showing Delivered",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: null
                },
                {
                    id: 106,
                    title: "Status of some DZ, DL and XB shipments showing Delivered",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: null
                },
                {
                    id: 107,
                    title: "Status of some DZ, DL and XB shipments showing Delivered",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: null
                },
                {
                    id: 108,
                    title: "Status of some DZ, DL and XB shipments showing Delivered",
                    case: "WrongAddress Correction Charges",
                    dateTime: "04/09/2020, 09:20 AM",
                    awb: 126318028397,
                    messages: null
                }
            ]
        },

        tutorialOptions: {
            useKeyboardNavigation: true,
            labels: {
                buttonSkip: "Skip this",
                buttonPrevious: `Previous`,
                buttonNext: "Next",
                buttonStop: "Finish",
            },
        },
    },


    mutations: {
        seterrorbutton(state, items) {
            state.errorButton = items
        },
        setRateAmount(state,items) {
            state.rateAmount = items
        },
        setWarehouses(state, items) {
            state.warehouses = items
        },
        setConsignors(state, items) {
            state.consignors = items
        },
        setCharts(state, items) {
            state.chartdata = items
        },
        setManifestsItems(state, items) {
            state.manifestsItems = items
        },

        setModes(state, items) {
            state.modes = items
        },

        setNotifstate(state,items) {
            state.notifState = items
        },

        setSellerstate(state,items) {
            state.sellerState = items
        },

        setAllmodes(state, items) {
            state.mode = items
        },

        setChannels(state, items) {
            state.channels = items
        },

        setCarriers(state, items) {
            state.carriers = items
        },

        setUser(state, items) {
            state.user = Object.assign(state.user, items)
        },

        setGlobal(state, items) {
            state.global = Object.assign(state.global, items)
        },

        setDue(state, items) {
            state.due = items.due
        },

        setTab(state, items) {
            state.tab = items
        },

        setFilter(state, items) {
            state.filters = items
        },

        setWindowWidth(state) {
            state.windowWidth = window.innerWidth;
        },

        setNotifications(state, items) {
            state.notifications = items
        },

        setRegistrationCompleted(state) {
            state.user.registrationCompleted = !state.user.registrationCompleted;
        },

        setShipmentItems(state, items = []) {

            state.shipmentItems = items.map(i => {
                return i;
            });

        },

        updateKycData(state, payload) {
            state.kycInfo = payload
        },

        setAPICred(state,payload) {
            state.apiCredentials = payload
        },


        setCustomerObj(state, items) {
            state.customerObj = items
        }
    },


    getters: {
        user: state => state.user,
        filters: state => state.filters,
        kycInfo: state => state.kycInfo,
        global: state => state.global,
        tab: state => state.tab,
        notifications: state => state.notifications,
        shipmentItems: state => state.shipmentItems,
        billingInvoicesItems: state => state.billingInvoicesItems,
        billingTransactionItems: state => state.billingTransactionItems,
        manifestsItems: state => state.manifestsItems,
        ndrItems: state => state.ndrItems,
        codItems: state => state.codItems,
        shipmentEditItems: state => state.shipmentEditItems,
        orderDetail: state => state.singleOrder.order,
        ticketUpdates: state => state.ticketUpdates,
        orderShippingStages: state => state.singleOrder.shippingStages,
        orderTableFields: state => state.singleOrder.tableFields,
        orderTableItems: state => state.singleOrder.tableItems,
        connectors: state => state.connectors,
        carriers: state => state.carriers,
        warehouses: state => state.warehouses,
        modes: state => state.modes,
        mode: state => state.mode,
        channels: state => state.channels,
        supportTickets: state => state.supportTickets,
        getChartById: (state) => (id) => {
            return state.charts.find(chart => chart.id === id)
        },
        chartdata: state => state.chartdata,
        getTicketById: (state) => (id) => {
            return state.supportTickets.activeTickets.find(activeTicket => activeTicket.id === id)
        },
        tutorialOptions: state => state.tutorialOptions,
        getTutorialById: (state) => (id) => {
            return state.tutorials.find(tutorial => tutorial.id === id)
        },
    },


    actions: {
        logout({ commit }) {
            commit('setUser', {})
            localStorage.removeItem("vuex");
            localStorage.removeItem("_secure__ls__metadata")
            // router.push("/login");
            location.reload()
        },

        unauthorized({commit}) {
            router.push('/unauthorized')
        },

        notFound({commit}) {
            router.push('/**')
        },

        login({commit}, payload) {
            commit('setUser', payload)
            if (payload.preference == "Shipment Tracking" || ((payload.kycFrom == 1 && payload.status != 1) && (payload.preference == "" || !payload.preference))) {
                router.push('/sign-up-detail')
            } else {
                router.push('/dashboard')
            }
            
        },

        async dueUpdate({commit}) {
            try {
                const res = await axios.get('/v3/due')
                commit('setGlobal', {overview: res.data})
            } catch(e) {
                // statements
                console.log(e);
            }
        }
    },


    modules: {},


    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ],
});
