<template>
  <div class="container mw596">
    <div class="row pt-52 pt-md-32">
      <div class="col-2">
        <b-button @click="selectMethod('AddFundsMethod')" variant="xxs">
          <i class="icon-previous fs9 mr-10 text-gray-600"></i>
          <span class="fs12 lh14 fw500 wsnw">
            Go back
          </span>
        </b-button>
      </div>
      <div class="col-8">
        <div class="d-flex flex-column align-items-center">
          <img src="@/assets/svg/neft.svg" alt="" />
          <span class="fs16 lh24 mt-24">
            <strong>Bank Detail for NEFT</strong>
          </span>
        </div>
      </div>
    </div>


    <!-- <div class="row mt-15 fs14" v-if="bankDetails.length">
      <div class="col">
        <strong>Kindly pay to below dedicated account, your payment will be automatically credited to your Shyplite account.</strong>
      </div>
    </div> -->


    <!-- <div class="row justify-content-between pt-14 pb-14">
      <div class="col">
        <ul class="lsnone p-0 mb-0">
          <li class="border-bottom-dashed">
            <div class="row h40px align-items-center">
              <div class="col-6 col-md-6">
                <span class="text-gray-600">
                  Bank
                </span>
              </div>
              <div class="col-6 col-md-6 text-right">
                <img src="@/assets/images/icici.png" class="icici" alt="" v-if="bankDetails.length && bankDetails[0].type == 'cashfreeicic' " />
                <img src="@/assets/images/yesbank.png" class="yesbank" alt="" v-if="bankDetails.length && bankDetails[0].type == 'cashfreeyesb' " />
                <img src="@/assets/svg/hdfc.svg" alt="" v-if="!bankDetails.length" />
                <span class="text-gray-600">
                  {{bankDetails.length ? bankDetails[0].type == "cashfreeicic" ? "ICICI Bank" : "YES Bank" : "HDFC Bank"}}
                </span>
              </div>
            </div>
          </li>
          <li class="border-bottom-dashed">
            <div class="row h40px align-items-center">
              <div class="col-6 col-md-6">
                <span class="text-gray-600">
                  Name
                </span>
              </div>
              <div class="col-6 col-md-6 text-right">
                <span class="text-gray-600">
                  Simba Logistics Private Limited
                </span>
              </div>
            </div>
          </li>
          <li class="border-bottom-dashed">
            <div class="row h40px align-items-center">
              <div class="col-6 col-md-6">
                <span class="text-gray-600">
                  Account No
                </span>
              </div>
              <div class="col-6 col-md-6 text-right">
                <span class="text-gray-600">
                  {{bankDetails.length ? bankDetails[0].accountNumber : '50200020918334'}}
                </span>
              </div>
            </div>
          </li>
          <li class="border-bottom-dashed">
            <div class="row h40px align-items-center">
              <div class="col-6 col-md-6">
                <span class="text-gray-600">
                  IFSC code
                </span>
              </div>
              <div class="col-6 col-md-6 text-right">
                <span class="text-gray-600">
                  {{bankDetails.length ? bankDetails[0].ifsc : 'HDFC0000043'}}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div> -->

    <div class="row justify-content-between pt-14 pb-14">
        <ul class="filter-list settings d-flex justify-content-center">
          <li v-for="bank in bankDetails" :key="bank.id" :class="[{ active: selected === bank.type }]">
              <b-link @click="showDetails(bank.type)" ref="banklist" class="btn-filter">

                  <p data-tooltip="Instant Credit" data-tooltip-location="top" v-if="bank.type == 'cashfreeicic'">
                    <img src="@/assets/images/icici.png" class="icici" alt="" />
                    {{bank.type == 'cashfreeicic' ? 'ICICI BANK ': ''}}
                  </p>

                  <p data-tooltip="Instant Credit" data-tooltip-location="top" v-if="bank.type == 'cashfreeyesb'">
                    <img src="@/assets/images/yesbank.png" class="yesbank mr-5" alt="" />
                    {{bank.type == 'cashfreeyesb' ? 'YES BANK': ''}}
                  </p>

                  <p data-tooltip="Credit upto 24 Working hours" data-tooltip-location="top" v-if="bank.type == 'hdfc'">
                    <img src="@/assets/svg/hdfc.svg" class="mr-5" alt="" />
                    {{bank.type == 'hdfc' ? 'HDFC BANK ': ''}}
                  </p>

                  <p data-tooltip="Credit upto 6-8 Working hours" data-tooltip-location="top" v-if="bank.type == 'razorpay'">
                    <img src="@/assets/images/rbl.png" class="rbl mr-5" alt="" />  
                    {{bank.type == 'razorpay' ? 'RATNAKAR BANK ': ''}}
                  </p>
                  
              </b-link>
          </li>
        </ul>
    </div>

    <div class="row justify-content-between pt-14 pb-14" v-if="details.length">
      <div class="col">
        <ul class="lsnone p-0 mb-0">
          <li class="border-bottom-dashed">
            <div class="row h40px align-items-center">
              <div class="col-6 col-md-6">
                <span class="text-gray-600">
                  Bank
                </span>
              </div>
              <div class="col-6 col-md-6 text-right text-gray-600">

                  {{ details[0].type == 'cashfreeicic' ? 'ICICI BANK ' : details[0].type == 'cashfreeyesb' ? 'YES BANK' : details[0].type == 'hdfc' ? 'HDFC BANK' : details[0].type == 'razorpay' ? 'RATNAKAR BANK' : ''}}
                  
                </span>
              </div>
            </div>
          </li>
          <!-- {{details[0]}} -->
          <li class="border-bottom-dashed">
            <div class="row h40px align-items-center">
              <div class="col-6 col-md-6">
                <span class="text-gray-600">
                  Name
                </span>
              </div>
              <div class="col-6 col-md-6 text-right">
                <span class="text-gray-600">
                  Simba Logistics Private Limited
                </span>
              </div>
            </div>
          </li>
          <li class="border-bottom-dashed">
            <div class="row h40px align-items-center">
              <div class="col-6 col-md-6">
                <span class="text-gray-600">
                  Account No
                </span>
              </div>
              <div class="col-6 col-md-6 text-right">
                <span class="text-gray-600">
                  {{details[0].accountNumber}}
                </span>
              </div>
            </div>
          </li>
          <li class="border-bottom-dashed">
            <div class="row h40px align-items-center">
              <div class="col-6 col-md-6">
                <span class="text-gray-600">
                  IFSC code
                </span>
              </div>
              <div class="col-6 col-md-6 text-right">
                <span class="text-gray-600">
                  {{details[0].ifsc}}
                </span>
              </div>
            </div>
          </li>
          <li class="border-bottom-dashed">
            <div class="row h40px align-items-center">
              <div class="col-6 col-md-6">
                <span class="text-gray-600">
                  Credit Timeline
                </span>
              </div>
              <div class="col-6 col-md-6 text-right">
                <span class="text-gray-600" >
                  {{ details[0].type == 'cashfreeicic' || details[0].type == 'cashfreeyesb' ? 'INSTANT' : details[0].type == 'hdfc' ? 'Upto 24 Working hours' : details[0].type == 'razorpay' ? 'Upto 6-8 Working hours' : ''}}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="row mt-15 fs12 pb-15" v-if="bankDetails.length">
      <div class="col">
        <strong>**Credit timeline indicates approximate time for amount to be credited to your Shyplite account, after remittance is received into the respective bank account.</strong>
      </div>
    </div>

  </div>
</template>

<script>
import { VMoney } from "v-money";

export default {
  name: "AddFundsNeft",
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      bankDetails:{},
      bankArray:['','HDFC BANK','RATNAKAR BANK','','ICICI BANK','YES BANK'],
      selected:null,
      details:[]
    };
  },
  directives: { money: VMoney },
  methods: {
    selectMethod(variable) {
      this.$emit("selectTab", variable);
    },
    showDetails(type) {
      this.selected = type
      this.details = []
      this.details = this.bankDetails.filter(i => i.type == type)
    },
    async getBank() {
      try {
        const res = await this.axios.get('/payment')
        const bankDetails = res.data.bankDetails || [];
        if(!bankDetails.length) {
            bankDetails.push({
              accountNumber: '50200020918334',
              ifsc: 'HDFC0000043',
              type: 'hdfc',
              id:1
          });
        }
        this.bankDetails = bankDetails
        this.details = [this.bankDetails[0]]
        this.selected = this.details[0].type
      } catch(e) {
        this.popToast("failed", "Failed!", "Data Fetching Failed Please Try Again Later");
        console.log(e);
      }
      
    }
  },
  created() {
    this.getBank();
  }
};
</script>

<style type="text/css" scoped>
  .icici {
    max-width: 3rem;
    max-height: 1.8rem;
  }
  .yesbank {
    max-width: 4rem;
    max-height: 2.5rem;
  }
  .rbl{ 
    max-width: 3rem;
    max-height: 2rem;}
  .filter-list {
    overflow-x: unset !important;
    overflow-y: unset !important;
  }
  /* 
  You want a simple and fancy tooltip?
  Just copy all [data-tooltip] blocks:
*/
[data-tooltip] {
  position: relative;
  z-index: 10;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px); /* 5px is the size of the arrow */
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 5px 10px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 0.8rem;
  background-color: rgba(59, 72, 80, 0.9);
  background-image: linear-gradient(30deg,
    rgba(59, 72, 80, 0.44),
    rgba(59, 68, 75, 0.44),
    rgba(60, 82, 88, 0.44));
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, -5px) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: '';
  border-style: solid;
  border-width: 5px 5px 0px 5px; /* CSS triangle */
  border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
  transition-duration: 0s; /* If the mouse leaves the element, 
                              the transition effects for the 
                              tooltip arrow are "turned off" */
  transform-origin: top;   /* Orientation setting for the
                              slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}
/* 
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}
/*
  That's it.
*/






/*
  If you want some adjustability
  here are some orientation settings you can use:
*/

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
  left: auto;
  right: calc(100% + 5px);
  bottom: 50%;
}

/* Tooltip */
[data-tooltip-location="left"]:before {
  transform: translate(-5px, 50%) scale(0.5);
}
[data-tooltip-location="left"]:hover:before {
  transform: translate(-5px, 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]:after {
  border-width: 5px 0px 5px 5px;
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="left"]:hover:after {
  transform: translateY(50%) scaleX(1);
}



/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
  left: calc(100% + 5px);
  bottom: 50%;
}

[data-tooltip-location="right"]:before {
  transform: translate(5px, 50%) scale(0.5);
}
[data-tooltip-location="right"]:hover:before {
  transform: translate(5px, 50%) scale(1);
}

[data-tooltip-location="right"]:after {
  border-width: 5px 5px 5px 0px;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="right"]:hover:after {
  transform: translateY(50%) scaleX(1);
}



/* BOTTOM */
[data-tooltip-location="bottom"]:before,
[data-tooltip-location="bottom"]:after {
  top: calc(100% + 5px);
  bottom: auto;
}

[data-tooltip-location="bottom"]:before {
  transform: translate(-50%, 5px) scale(0.5);
}
[data-tooltip-location="bottom"]:hover:before {
  transform: translate(-50%, 5px) scale(1);
}

[data-tooltip-location="bottom"]:after {
  border-width: 0px 5px 5px 5px;
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
  transform-origin: bottom;
}










/* Settings that make the pen look nicer */
html {
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 1.2em;
  background: linear-gradient(45deg, #243949, #2cacd1, #35eb93);
  background-size: 120% 120%;
  animation: moveFocus 5s ease infinite alternate;
}
@keyframes moveFocus { 
  0%   { background-position: 0% 100% }
  100% { background-position: 100% 0% }
}

body {
  background: none;    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
}

main {
  padding: 0 4%;
  display: flex;
  flex-direction: row;
  margin: auto 0;
}


.example-elements {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding-right: 4%;
}

.example-elements p {
  padding: 6px;
  display: inline-block;
  margin-bottom: 5%;
  color: #fff;
}
.example-elements p:hover {
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  padding-left: 5px;
  padding-right: 5px;
}

.example-elements a {
  margin-left: 6px;
  margin-bottom: calc(5% + 10px);
  color: #76daff;
  text-decoration: none;
}
.example-elements a:hover {
  margin-bottom: calc(5% + 9px);
  border-bottom: 1px solid #76daff;
}

.example-elements button {
  margin-bottom: 20px;
}

.info-wrapper {
  flex-grow: 8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  padding-left: 6%;
  border-left: 3px solid #35ea95;
}

.info-wrapper p {
  color: rgba(255, 255, 255, 0.69);
}
.info-wrapper p {
  max-width: 600px;
  text-align: justify;
}

.info-wrapper .title-question {
  display: block;
  color: #fff;
  font-size: 1.36em;
  font-weight: 500;
  padding-bottom: 24px;
}


@media (max-height: 450px) {
  main {
    margin: 2rem 0;
  }
}

@media (max-width: 800px) {
  html {
    font-size: 0.9em;
  }
}

/* Thumbnail settings */
@media (max-width: 750px) {
  html {
    animation-duration: 0.6s;
    font-size: 1em;
  }
  body {
    display: flex;
    background: none;
    height: 100%;
    margin: 0px;
  }
  main {
    font-size: 1.1em;
    padding: 6%;
  }
  .info-wrapper p:before,
  .info-wrapper p:after {
    display: none;
  }
  .example-elements {
    max-width: 150px;
    font-size: 22px;
  }
  .example-elements a, button {
    display: none;
  }
  .example-elements p:before, 
  .example-elements p:after {
    visibility: visible;
    opacity: 1;
  }
  .example-elements p:before {
    content: "Tooltip";
    font-size: 20px;
    transform: translate(-50%, -5px) scale(1);
  }
  .example-elements p:after {
    transform: translate(-50%, -1px) scaleY(1);
  }
  
  
  [data-tooltip]:after {
    bottom: calc(100% + 3px);
  }
  [data-tooltip]:after {
    border-width: 7px 7px 0px 7px;
  }
}
</style>
