var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth log-in" }, [
    _c("div", { staticClass: "auth-wrapper" }, [
      _c(
        "div",
        { staticClass: "card mw468" },
        [
          _c(
            "b-form",
            {
              staticClass: "card__content pt-60 pb-52 px-40 px-md-56",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.login($event)
                }
              }
            },
            [
              _c("img", {
                staticClass: "d-block w-100 mw170 mx-auto mb-60",
                attrs: { src: require("@/assets/svg/logo_full.svg"), alt: "" }
              }),
              _c(
                "b-form-group",
                {
                  staticClass: "mb-28",
                  attrs: {
                    id: "email-field",
                    label: "Email",
                    "label-for": "email"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "email",
                      required: "",
                      id: "email",
                      placeholder: "Your email ID",
                      trim: ""
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "mb-40",
                  attrs: {
                    id: "password-field",
                    label: "Password",
                    "label-for": "password",
                    "invalid-feedback": _vm.invalidFeedback,
                    "valid-feedback": _vm.validFeedback,
                    state: _vm.state
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "password",
                      required: "",
                      type: "password",
                      placeholder: "Your secure password",
                      trim: ""
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  })
                ],
                1
              ),
              _c("input", {
                attrs: { id: "hutk_cookie", required: "", type: "hidden" },
                domProps: { value: _vm.hubspotcookie }
              }),
              _c(
                "b-button",
                {
                  staticClass: "w-100 mb-20 mb-md-40",
                  attrs: {
                    type: "submit",
                    disabled: _vm.submitting,
                    variant: "primary",
                    size: "lg"
                  }
                },
                [
                  _vm._v(" " + _vm._s(_vm.loginbtn) + " "),
                  _vm.submitting
                    ? _c("b-spinner", { staticClass: "ml-10" })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-column align-items-center" },
                [
                  _c(
                    "b-link",
                    {
                      staticClass: "text-underline mb-12 mb-md-16",
                      attrs: { href: "/forgot-password" }
                    },
                    [_vm._v(" Forgot your password? ")]
                  ),
                  _c(
                    "span",
                    { staticClass: "fs12 lh20 text-gray-800" },
                    [
                      _vm._v(" New here? "),
                      _c("b-link", { attrs: { href: "/sign-up" } }, [
                        _vm._v("Sign Up")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "mob_footer bottom-bar d-flex justify-content-between mx-40"
        },
        [
          _c(
            "span",
            { staticClass: "fs12 lh20 text-gray-400 mb-16 mb-md-32" },
            [_vm._v(" © 2021 Shyplite — All Rights Reserved. ")]
          ),
          _c("ul", { staticClass: "lsnone p-0 mb-16 mb-md-32" }, [
            _c(
              "li",
              [
                _c(
                  "b-link",
                  {
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/tou"
                    }
                  },
                  [_vm._v("Terms")]
                ),
                _c(
                  "b-link",
                  {
                    staticClass: "ml-20",
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/privacy"
                    }
                  },
                  [_vm._v("Privacy")]
                ),
                _c(
                  "b-link",
                  {
                    staticClass: "ml-20",
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/contact/"
                    }
                  },
                  [_vm._v("Contact Us")]
                )
              ],
              1
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }