<template>
  <div class="container mw596">
    <div class="row pt-52 pt-md-32">
      <div class="col-2">
        <b-button @click="selectMethod('AddFundsMethod', 'back')" variant="xxs">
          <i class="icon-previous fs9 mr-10 text-gray-600"></i>
          <span class="fs12 lh14 fw500 wsnw"> Go back </span>
        </b-button>
      </div>
      <div class="col-8">
        <div class="d-flex flex-column align-items-center">
          <img src="@/assets/svg/neft.svg" alt="" />
          <span class="fs16 lh24 mt-24"> <strong>Submit COD Amount</strong> </span>
        </div>
      </div>
    </div>

    <div class="row pt-32 pb-8">
      <div class="col" :class="{'col-md-9' : otpsent}">
        <span class="fs14"><strong>Expected Cod</strong> : &#8377{{codDue}}</span> <span v-if="(minAmount != null || minAmount != undefined) && minAmount > 200" class="fs14"><strong>Minimum Amount</strong> : &#8377{{minAmount}}</span>
        <b-form-group
          id="amount-field"
          label-for="amount-value"
          class="mt-5"
        >
          <b-input
            id="amount-value"
            placeholder="Enter Amount"
            v-model="coupon"
            :disabled="otpsent"
          ></b-input>
        </b-form-group>
      </div>
      <div class="col-md-3" v-if="otpsent">
        <span class="fs14" style="color:#fff">H</span>
        <b-form-group
          id="otp-field"
          label-for="otp-value"
          class="mt-5"
        >
          <b-input
            id="otp-value"
            placeholder="Enter OTP"
            v-model="otp"
            maxlength="6"
          ></b-input>
        </b-form-group>
      </div>
    </div>

    <div class="row pl-10" v-if="Cost != null">
      <span class="text-gray-600 fs14"><strong>NOTE</strong> : Closest amount of &#8377{{Cost}} will be adjusted from expected COD</span>
    </div>

    <div class="row pt-0 pt-md-10 pb-0 pb-md-18">
      <div class="col text-right">
        <b-button
          @click="adjustCod(coupon,otp)"
          variant="primary"
          :disabled="adding"
          class="w-sm-100"
          v-if="otpsent"
        >
          <span class="fw700"> Add to Balance <b-spinner class="spinner" v-if="adding"></b-spinner> </span>
        </b-button>
        <b-button
          v-else
          @click="selectMethod('AddFundsSuccessfull', 'submit', coupon)"
          variant="primary"
          :disabled="sendingOtp"
          class="w-sm-100"
        >
          <span class="fw700"> <b-spinner class="spinner" v-if="sendingOtp"></b-spinner> Submit </span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddFundsCod",
  data() {
    return {
      coupon: "",
      codDue:null,
      sendingOtp:false,
      otpsent:false,
      otp:null,
      earlycod:null,
      Cost : null,
      adding :false,
      minAmount: null
    };
  },
  created() {
    this.getexpectedCod();
  },
  methods: {
    async getexpectedCod() {
      try {
        const res = await this.axios.get('expectedCod')
        if (res.data.due != null) {
          this.codDue = res.data.due
          this.earlycod = res.data.earlyCOD 
          this.minAmount = res.data.minAmount
        } else {
          this.codDue = 0
          this.earlycod = null
        }
        
      } catch(e) {
        // statements
        console.log(e);
      }
    },
    async selectMethod(variable, path, amount) {
      if (path == "back") {
        this.$emit("selectTab", variable);
      } else {
        if (amount < 200) {
          this.popToast('failed','Failed','Value must be greater than or equal to ₹200')
          return false
        } else if (amount > this.codDue) {
          this.popToast('failed','Failed','Amount must be less than or equal to Expected COD')
          return false
        } else if (amount < this.minAmount) {
          this.popToast('failed','Failed','Amount must be greater than or equal to Minimum Amount')
          return false
        } else {
          this.sendingOtp = true
          try {
            const res = await this.axios.post("/sendcodOtp", {adjust : amount, earlyCOD : this.earlycod});
            if (res.data.success) {
              this.popToast("booked", "Success!", res.data.message);
              this.otpsent = true
              this.Cost  = res.data.cost
            } else {
              this.otpsent = false
              this.Cost = null
              this.popToast("failed", "Failed!",res.data.message);
            }
          } catch (error) {
            this.otpsent = false
            this.Cost = null
            this.popToast(
              "failed",
              "Failed!",
              "Process Failed!! Please Try again Later"
            );
          }
          this.sendingOtp = true
        }
        
      }
    },
    async adjustCod (amount,Otp) {
      this.adding = true
      try {
        const obj = {
          adjust:amount,
          otp:Otp
        }
        const res = await this.axios.post("/adjustCod",obj)
        console.log(res)
        if (res.data.success) {
          this.popToast("booked", "Success!", "Balance Added Successfully");
          setTimeout(() => { 
            window.location.reload();
          }, 1000)
        } else {
          this.popToast("failed", "Failed!", res.data.message);
        }
      } catch(e) {
        this.popToast("failed", "Failed!", e);
        console.log(e);
      }
      this.adding = false
    }
  },
};
</script>

<style type="text/scss" scoped>

.spinner {
      width: 1rem;
      height: 1rem;
}
</style>
