<template>
  <div class="container">
    <div class="row py-0 py-md-24" :class="{'d-flex justify-content-center' : coupon != ''}">
      <div class="payment-wrap" :class="{'col-md-12' : coupon != ''}">
         <div class="col-md-4" :class="{'col-md-6 mx-auto' : coupon != ''}">
          <div class="payment-card" @click="selectMethod('AddFundsPayu')">
            <img class="mt-5 mx-auto payu" src="@/assets/images/PayU2.png" alt="" />
            <span class="fs16 lh24 mw180 text-center mx-auto">
              Pay by PayU
            </span>
            <b-button variant="billing" @click="selectMethod('AddFundsPayu')" class="w-100">
              Select
            </b-button>
          </div>
        </div>
        <div class="col-md-4" :class="{'col-md-6' : coupon != ''}">
          <div class="payment-card razorpay"  @click="selectMethod('AddFundsNetbanking')">
            <img
              class="payment-icon mx-auto"
              src="@/assets/images/razorpay.png"
              alt=""
              style="width: 12rem"
            />
            <span class="fs16 lh24 mw180 text-center mx-auto">
              Pay by Debit/Credit Card | Wallet
            </span>
            <b-button
              @click="selectMethod('AddFundsNetbanking')"
              variant="billing"
              class="w-100"
            >
              Select
            </b-button>
          </div>
        </div>
        <div class="col-md-4" :class="{'col-md-6' : coupon != ''}">
          <div class="payment-card" @click="selectMethod('AddFundsPaytm')">
            <img class="pt-24" src="@/assets/svg/paytm.svg" alt="" />
            <span class="fs16 lh24 mw180 text-center mx-auto">
              Pay by Netbanking | UPI | Paytm
            </span>
            <b-button variant="billing" @click="selectMethod('AddFundsPaytm')" class="w-100">
              Select
            </b-button>
          </div>
        </div>
        <div class="col-md-4" :class="{'d-none' : coupon != ''}">
          <div class="payment-card"  @click="selectMethod('AddFundsNeft')">
            <img class="payment-icon" src="@/assets/svg/neft.svg" alt="" />
            <span class="fs16 lh24 mw180 text-center mx-auto">
              Bank Detail for NEFT
            </span>
            <b-button
              @click="selectMethod('AddFundsNeft')"
              variant="billing"
              class="w-100"
            >
              Select
            </b-button>
          </div>
        </div>
        <!-- <div class="col-md-4">
          <div class="payment-card" @click="selectMethod('AddFundsBharatPe')">
            <img class="pt-24" src="@/assets/svg/bharatpe.svg" style="max-height: 4.7rem" alt="" />
            <span class="fs16 lh24 mw180 text-center mx-auto">
              BharatPe UPI
            </span>
            <b-button variant="billing" @click="selectMethod('AddFundsBharatPe')" class="w-100">
              Select
            </b-button>
          </div>
        </div> -->
        <!-- <div class="col-md-4">
          <div class="payment-card" >
            <img class="payment-icon" src="@/assets/svg/cheque.svg" alt="" />
            <span class="fs16 lh24 mw180 text-center mx-auto">
              Submit cheque deposit details
            </span>
            <b-button variant="billing" class="w-100">
              Select
            </b-button>
          </div>
        </div> -->
        <div class="col-md-4" :class="{'d-none' : coupon != ''}">
          <div class="payment-card" @click="selectMethod('AddFundsCoupon')">
            <img
              class="payment-icon"
              src="@/assets/svg/redeem-coupon.svg"
              alt=""
            />
            <span class="fs16 lh24 mw180 text-center mx-auto">
              Redeem Coupon
            </span>
            <b-button @click="selectMethod('AddFundsCoupon')" variant="billing" class="w-100">
              Select
            </b-button>
          </div>
        </div>


        <div v-if="$store.getters.user.prepaid && $store.state.global.isCodSeller && $store.getters.user.id == 165" class="col-md-4" :class="{'d-none' : coupon != ''}">
          <div class="payment-card" @click="selectMethod('AddFundsCod')">
            <svg class="payment-icon cod-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path class="cod-fill" d="M320 96H192L144.6 24.88C137.5 14.24 145.1 0 157.9 0H354.1C366.9 0 374.5 14.24 367.4 24.88L320 96zM192 128H320C323.8 130.5 328.1 133.3 332.1 136.4C389.7 172.7 512 250.9 512 416C512 469 469 512 416 512H96C42.98 512 0 469 0 416C0 250.9 122.3 172.7 179 136.4C183.9 133.3 188.2 130.5 192 128V128zM276.1 224C276.1 212.9 267.1 203.9 255.1 203.9C244.9 203.9 235.9 212.9 235.9 224V230C230.3 231.2 224.1 232.9 220 235.1C205.1 241.9 192.1 254.5 188.9 272.8C187.1 283 188.1 292.9 192.3 301.8C196.5 310.6 203 316.8 209.6 321.3C221.2 329.2 236.5 333.8 248.2 337.3L250.4 337.9C264.4 342.2 273.8 345.3 279.7 349.6C282.2 351.4 283.1 352.8 283.4 353.7C283.8 354.5 284.4 356.3 283.7 360.3C283.1 363.8 281.2 366.8 275.7 369.1C269.6 371.7 259.7 373 246.9 371C240.9 370 230.2 366.4 220.7 363.2C218.5 362.4 216.3 361.7 214.3 361C203.8 357.5 192.5 363.2 189 373.7C185.5 384.2 191.2 395.5 201.7 398.1C202.9 399.4 204.4 399.9 206.1 400.5C213.1 403.2 226.4 407.4 235.9 409.6V416C235.9 427.1 244.9 436.1 255.1 436.1C267.1 436.1 276.1 427.1 276.1 416V410.5C281.4 409.5 286.6 407.1 291.4 405.9C307.2 399.2 319.8 386.2 323.1 367.2C324.9 356.8 324.1 346.8 320.1 337.7C316.2 328.7 309.9 322.1 303.2 317.3C291.1 308.4 274.9 303.6 262.8 299.9L261.1 299.7C247.8 295.4 238.2 292.4 232.1 288.2C229.5 286.4 228.7 285.2 228.5 284.7C228.3 284.3 227.7 283.1 228.3 279.7C228.7 277.7 230.2 274.4 236.5 271.6C242.1 268.7 252.9 267.1 265.1 268.1C269.5 269.7 283 272.3 286.9 273.3C297.5 276.2 308.5 269.8 311.3 259.1C314.2 248.5 307.8 237.5 297.1 234.7C292.7 233.5 282.7 231.5 276.1 230.3L276.1 224z"/></svg>
            <span class="fs16 lh24 mw180 text-center mx-auto">
              Pay By Cod
            </span>
            <b-button @click="selectMethod('AddFundsCod')" variant="billing" class="w-100">
              Select
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row pb-0 pt-22 pb-md-40 pt-md-0">
      <i class="icon-support fs16 text-yellow pr-8"></i>
      <span class="vam">
        Select payment method to go to the next step.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BillingAddFundsMethod",
  data() {
    return {
      coupon: this.$store.state.rateAmount || ""
    }
  },
  methods: {
    selectMethod(variable) {
      this.$emit("selectTab", variable);
    },
  },
};
</script>

<style type="text/scss" scoped>
  .razorpay:hover .payment-icon {
    filter: unset !important;
  }
  .payu {
    max-height: unset !important;
    padding: 0.5rem;
    height: 5rem;
    width: 11rem;
  }

  .cod-icon {
    max-width: 15rem !important;
    max-height: 4.2rem !important;
    padding-top : 1.5rem !important;
  }

  .cod-fill {
    fill:#828b9d !important;
  }
  
</style>










