var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row pt-134 pb-140 pt-md-72 pb-md-72" }, [
      _c(
        "div",
        { staticClass: "col text-center" },
        [
          _c("img", {
            staticClass: "pb-24",
            attrs: {
              src: require("@/assets/svg/payment-successfull.svg"),
              alt: "Payment Succesfull!"
            }
          }),
          _c("span", { staticClass: "fs24 lh32 d-block fw500 pb-16" }, [
            _vm._v(" Payment Successfull ")
          ]),
          _vm.amountupdated
            ? _c(
                "p",
                { staticClass: "fs16 lh24 mx-auto text-center mw366 pb-32" },
                [
                  _vm._v(
                    " You have successfully added " +
                      _vm._s(_vm._f("currency")(_vm.amountupdated, "inr")) +
                      " to your account. You will be redirected to to your dashboard within 5 seconds. "
                  )
                ]
              )
            : _vm._e(),
          _vm.paytmamount
            ? _c(
                "p",
                { staticClass: "fs16 lh24 mx-auto text-center mw366 pb-32" },
                [
                  _vm._v(
                    " You have successfully added " +
                      _vm._s(_vm._f("currency")(_vm.paytmamount, "inr")) +
                      " to your account. You will be redirected to to your dashboard within 5 seconds. "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "b-button",
            {
              attrs: { variant: "primary" },
              on: {
                click: function($event) {
                  return _vm.selectMethod("AddFundsMethod")
                }
              }
            },
            [_vm._v(" Okay, got it! ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }