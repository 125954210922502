<template>
  <div class="container mw596">
    <div class="row pt-52 pt-md-32">
      <div class="col-2">
        <b-button @click="selectMethod('AddFundsMethod','back')" variant="xxs">
          <i class="icon-previous fs9 mr-10 text-gray-600"></i>
          <span class="fs12 lh14 fw500 wsnw">
            Go back
          </span>
        </b-button>
      </div>
      <div class="col-8 my-15">
        <div class="d-flex flex-column align-items-center">
          <img src="@/assets/images/razorpay.png" style="height: 3rem" alt="" />
          <span class="fs16 lh24 mt-24 text-center text-md-left">
            <strong>Submit Amount to be Added</strong>
          </span>
        </div>
      </div>
    </div>
    <div class="row pt-32">
      <div class="col">
        <b-form-group id="amount-field" label="Amount" label-for="amount-value">
          <b-input-group append="₹">
            <b-input
              type="number"
              id="amount-value"
              placeholder="0.00"
              v-model="coupon"
              :disabled="this.$store.state.rateAmount != '' "
            ></b-input>
          </b-input-group>
          <label class="fs12 lh14 text-gray-600 mt-38 mt-md-12 mw448">
            <span v-if="planId">Amount</span><span v-else>Enter the amount</span> of funds you are adding to the platform, all
            additional information will be handled through RazorPay.
          </label>
        </b-form-group>
      </div>
    </div>
    <div v-if="planId && this.$store.state.rateAmount < 200" class="row col-md-12 mx-auto info-card">
      As the minimum recharge amount needs to be maintained at Rs.200, you are requested to recharge with Rs.200 to activate the selected plan.
    </div>
    <div class="row">
      <div class="col pt-20 pt-md-20 pb-30 pb-md-30 text-right">
        <b-button @click="selectMethod('AddFundsSuccessfull','submit')" variant="razorpay" size="lg">
          <img class="mr-12" src="@/assets/svg/razorpay.svg" alt="" />
          <span>
            Continue to RazorPay
          </span>
          <i class="icon-expand-menu fs7 ml-20"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
const headers = {
  // 'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
  'Content-Type': 'application/json',
}
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers = headers

export default {
  name: "AddFundsNetbanking",
  data() {
    return {
      planId:this.$store.state.global.planId,
      coupon: this.$store.state.global.planId ? (this.$store.state.rateAmount < 200 ? 200 : this.$store.state.rateAmount) : ""
    };
  },
  methods: {
    async selectMethod(variable, path) {
      if (path == "back") {
        this.$emit("selectTab", variable);
      } else {
        try {
          const response = await axios.post('/razorpay',{amount: this.coupon, templateID: this.planId})
          console.log(this.user)
          const user  = this.user
          console.log(response)
          if (!response.data.status) {
            this.popToast("failed", "Failed!", response.data.message)
          } else {
            const options = {
                "key": response.data.razorpayKey,
                "order_id": response.data.razorpayID,
                "amount": this.coupon * 100,
                "name": "Shyplite",
                "description": "Payment",
                "image": "https://shyplite.com/public/images/footer-logo.png",
                "handler": async function (response1) {
                    try {
                        const params = {
                            razorpay_order_id: response.data.razorpayID,
                            razorpay_payment_id: response1['razorpay_payment_id']
                        };
                        console.log(params);
                        const result = await axios.post('/razorpay/checkout', params);
                        window.location.reload();
                    }
                    catch (err) {
                      console.log(err)
                    }
                },
                "prefill": {
                    "name": `${user.sellerCompanyName}`,
                    "email": `${user.username}`,
                    "contact": `${user.contact}`
                },
                "theme": {"color": "#54a5ff"},
                "modal": {
                    "ondismiss": function (response) {
                    }
                }
            };
            const rzp = new Razorpay(options);
            rzp.open();
            event.preventDefault();
          }
          
          
        } catch (error) {
          console.log(error)
        }
      }
    },
  },
  computed: {
    ...mapState(["user"])
  },
};
</script>

<style lang="css" scoped>
  .info-card {
    font-weight: 700;
    padding: 0.3rem 0.8rem;
    background-color: #e5f0ff;
    border-radius: 5px;
  }
</style>

