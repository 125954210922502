var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      !_vm.authPages ? _c("AppSidebar") : _vm._e(),
      !_vm.authPages ? _c("AppMobileMenu") : _vm._e(),
      _c(
        "div",
        {
          staticClass: "app-content",
          class: { auth: _vm.authPages },
          attrs: { id: "app-content" }
        },
        [
          _c(
            "transition",
            { attrs: { name: _vm.transitionName } },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }