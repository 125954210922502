export default {
    methods: {
        popToast(variant, title, text) {
            const h = this.$createElement;

            const vNodesHead = h("div", { props: { type: "grow", small: true } }, [
                h("i", { class: [`icon-${variant}`, "fs10", "mr-12"] }),
                h("span", { class: ["title"] }, `${title}`),
            ]);

            const vNodesText = h("span", { class: ["text"] }, `${text}`);

            this.$bvToast.toast([vNodesHead, vNodesText], {
                solid: true,
                variant: variant,
                autoHideDelay: 5000,
                toaster: "b-toaster-top-right",
            });
        }
    }
}