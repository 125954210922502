var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mw596" }, [
    _c("div", { staticClass: "row pt-52 pt-md-32" }, [
      _c(
        "div",
        { staticClass: "col-2" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "xxs" },
              on: {
                click: function($event) {
                  return _vm.selectMethod("AddFundsMethod", "back")
                }
              }
            },
            [
              _c("i", { staticClass: "icon-previous fs9 mr-10 text-gray-600" }),
              _c("span", { staticClass: "fs12 lh14 fw500 wsnw" }, [
                _vm._v(" Go back ")
              ])
            ]
          )
        ],
        1
      ),
      _vm._m(0)
    ]),
    _c("div", { staticClass: "row pt-32 pb-8" }, [
      _c(
        "div",
        { staticClass: "col", class: { "col-md-9": _vm.otpsent } },
        [
          _c("span", { staticClass: "fs14" }, [
            _c("strong", [_vm._v("Expected Cod")]),
            _vm._v(" : ₹" + _vm._s(_vm.codDue))
          ]),
          _vm._v(" "),
          (_vm.minAmount != null || _vm.minAmount != undefined) &&
          _vm.minAmount > 200
            ? _c("span", { staticClass: "fs14" }, [
                _c("strong", [_vm._v("Minimum Amount")]),
                _vm._v(" : ₹" + _vm._s(_vm.minAmount))
              ])
            : _vm._e(),
          _c(
            "b-form-group",
            {
              staticClass: "mt-5",
              attrs: { id: "amount-field", "label-for": "amount-value" }
            },
            [
              _c("b-input", {
                attrs: {
                  id: "amount-value",
                  placeholder: "Enter Amount",
                  disabled: _vm.otpsent
                },
                model: {
                  value: _vm.coupon,
                  callback: function($$v) {
                    _vm.coupon = $$v
                  },
                  expression: "coupon"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.otpsent
        ? _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "span",
                { staticClass: "fs14", staticStyle: { color: "#fff" } },
                [_vm._v("H")]
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "mt-5",
                  attrs: { id: "otp-field", "label-for": "otp-value" }
                },
                [
                  _c("b-input", {
                    attrs: {
                      id: "otp-value",
                      placeholder: "Enter OTP",
                      maxlength: "6"
                    },
                    model: {
                      value: _vm.otp,
                      callback: function($$v) {
                        _vm.otp = $$v
                      },
                      expression: "otp"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]),
    _vm.Cost != null
      ? _c("div", { staticClass: "row pl-10" }, [
          _c("span", { staticClass: "text-gray-600 fs14" }, [
            _c("strong", [_vm._v("NOTE")]),
            _vm._v(
              " : Closest amount of ₹" +
                _vm._s(_vm.Cost) +
                " will be adjusted from expected COD"
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "row pt-0 pt-md-10 pb-0 pb-md-18" }, [
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _vm.otpsent
            ? _c(
                "b-button",
                {
                  staticClass: "w-sm-100",
                  attrs: { variant: "primary", disabled: _vm.adding },
                  on: {
                    click: function($event) {
                      return _vm.adjustCod(_vm.coupon, _vm.otp)
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "fw700" },
                    [
                      _vm._v(" Add to Balance "),
                      _vm.adding
                        ? _c("b-spinner", { staticClass: "spinner" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _c(
                "b-button",
                {
                  staticClass: "w-sm-100",
                  attrs: { variant: "primary", disabled: _vm.sendingOtp },
                  on: {
                    click: function($event) {
                      return _vm.selectMethod(
                        "AddFundsSuccessfull",
                        "submit",
                        _vm.coupon
                      )
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "fw700" },
                    [
                      _vm.sendingOtp
                        ? _c("b-spinner", { staticClass: "spinner" })
                        : _vm._e(),
                      _vm._v(" Submit ")
                    ],
                    1
                  )
                ]
              )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-8" }, [
      _c("div", { staticClass: "d-flex flex-column align-items-center" }, [
        _c("img", {
          attrs: { src: require("@/assets/svg/neft.svg"), alt: "" }
        }),
        _c("span", { staticClass: "fs16 lh24 mt-24" }, [
          _c("strong", [_vm._v("Submit COD Amount")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }