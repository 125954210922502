<template>
  <b-modal
    id="BillingAddFunds"
    modal-class="billing-modal"
    size="lg"
    :hide-header="headerHidden"
    hide-footer
    title="Add Funds"
    v-model="isOpen"
    ref="addFunds"
    @hideHeader="hideIt"
  >
    <template v-if="maintenance" v-slot:modal-header="{ close }">
      <div class="d-flex align-items-center justify-content-between w-100">
        <span class="fs18 lh28 fw500">
          Under Maintenance
        </span>
        <b-button variant="rounded" @click="close()">
          <i class="icon-close-variant"></i>
        </b-button>
      </div>
    </template>

    <template v-else v-slot:modal-header="{ close }">
      <div class="d-flex align-items-center justify-content-between w-100">
        <span class="fs18 lh28 fw500">
          Add Funds
        </span>
        <div class="step-wrap d-none d-md-flex">
          <div class="step" :class="{ active: !stepTwo }">
            <span class="step-number">1</span>
            <span class="step-text">Select Method</span>
          </div>
          <div class="step" :class="{ active: stepTwo }">
            <span class="step-number">2</span>
            <span class="step-text">Insert Details</span>
          </div>
        </div>
        <b-button variant="rounded" @click="close()">
          <i class="icon-close-variant"></i>
        </b-button>
      </div>
      <div class="d-block d-md-none w-100">
        <div class="step-wrap">
          <div class="step" :class="{ active: !stepTwo }">
            <span class="step-number">1</span>
            <span class="step-text">Select Method</span>
          </div>
          <div class="step" :class="{ active: stepTwo }">
            <span class="step-number">2</span>
            <span class="step-text">Insert Details</span>
          </div>
        </div>
      </div>
    </template>
    <!-- <component @selectTab="selectMethod" :is="selectedTab"></component> -->
    <div v-if="maintenance" class="py-120 text-center">
      <h4 class="mb-20">Page under maintenance</h4>
      <p>This page is currently undergoing scheduled maintenance. We should be back shortly.</p>
    </div>
    <component
    v-else
    :amountupdated="amountupdated" @updateAmount="updateAmount"
    :paytmamount="paytmamount" :payuamount="payuamount" @updatedpaytm="updatedpaytm" 
     @selectTab="selectMethod"  :is="selectedTab" ></component>
  </b-modal>
</template>

<script>
import AddFundsMethod from "@/components/AddFundsMethod.vue";
import AddFundsNetbanking from "@/components/AddFundsNetbanking.vue";
import AddFundsNeft from "@/components/AddFundsNeft.vue";
import AddFundsSuccessfull from "@/components/AddFundsSuccessfull.vue";
import AddFundsCoupon from "@/components/AddFundsCoupon.vue"
import AddFundsPaytm from "@/components/AddFundsPaytm.vue"
import AddFundsPayu from "@/components/AddFundsPayu.vue"
import AddFundsBharatPe from "@/components/AddFundsBharatPe.vue"
import AddFundsCod from "@/components/AddFundsCod.vue"

export default {
  name: "BillingAddFunds",
  data() {
    return {
      maintenance: false,
      headerHidden: false,
      stepTwo: false,
      isOpen: this.modalOpen,
      amountupdated:"",
      paytmamount:"",
      payuamount:"",
      tabs: [
        {
          id: 0,
          component: "AddFundsMethod",
          title: "Add Funds Method",
        },
        {
          id: 1,
          component: "AddFundsNetbanking",
          title: "Netbanking",
        },
        {
          id: 2,
          component: "AddFundsNeft",
          title: "Neft",
        },
        {
          id: 3,
          component: "AddFundsSuccessfull",
          title: "Payment Successfull!",
        },
        {
          id: 4,
          component: "AddFundsPayu",
          title: "PayU!",
        },
        {
          id: 5,
          component: "AddFundsCod",
          title: "COD!",
        }
      ],
      selected: "AddFundsMethod",
    };
  },
  components: {
    AddFundsNeft,
    AddFundsNetbanking,
    AddFundsSuccessfull,
    AddFundsMethod,
    AddFundsCoupon,
    AddFundsPaytm,
    AddFundsBharatPe,
    AddFundsPayu,
    AddFundsCod
  },
  mounted(){
    return {
      selectedTab: function() {
        return this.selected == "AddFundsMethod";
      },
    };
  },
  created() {
    return {
      selectedTab: function() {
        return this.selected == "AddFundsMethod";
      },
    };
  },
  updated() {
    return {
      selectedTab: function() {
        return this.selected == "AddFundsMethod";
      },
    };
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("BillingAddFunds");
    },
    hideIt() {
      this.$refs.addFunds.hideHeader = true;
    },
    selectMethod(variable) {
      this.selected = variable;
      if (this.selected == "AddFundsMethod") {
        this.stepTwo = false;
      } else {
        this.stepTwo = true;
      }
    },
    updateAmount(amount){
      this.amountupdated = amount
    },
    updatedpaytm(amount) {
      this.paytmamount = amount
    }
  },
  computed: {
    selectedTab: function() {
      return this.selected;
    },
  },
  watch: {
    isOpen: function(newValue) {
      if(newValue == false) {
        this.selected = 'AddFundsMethod'
        this.stepTwo = false
        this.$store.commit('setRateAmount', "");
        this.$store.commit('setGlobal', {planId:null});
        if(this.$store.state.sellerState == true) {
            this.$store.commit('setSellerstate', false)
        }
      } 
    },
    selected: function() {
      if (this.selected == "AddFundsSuccessfull") {
        this.headerHidden = true;
      } else {
        this.headerHidden = false;
      }
    },
  },
};
</script>
