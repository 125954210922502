
import hourtime from "./hourtime";

  export default function logdate (value) {
      
      const date = new Date(value);
      const month = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"][date.getMonth()];
      let longdate = `${month} ${date.getDate()}, ${date.getFullYear()}`;
      return longdate
  }
