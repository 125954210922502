import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import LogIn from "../views/auth/LogIn.vue"

Vue.use(VueRouter);

const unAuthenticatedRoutes = [
	"LogIn",
	"SignUp",
	"ForgotPassword",
	"ResetPassword",
];

const router = new VueRouter({
	mode: "history",
	linkActiveClass: "active",
	linkExactActiveClass: "exact-active",
	routes: [
		{
			path: "/",
			redirect: "/login"
		},
		{
			path: "/login",
			name: "LogIn",
			component: LogIn,
			meta: { transitionName: "slide" }
		},
		{
			path: "/dashboard",
			name: "Dashboard",
			component: () =>
			import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
			meta: { 
				transitionName: "slide",
			}
		},
		{
		path: "/sign-up",
		name: "SignUp",
		component: () =>
			import(/* webpackChunkName: "sign-up" */ "../views/auth/SignUp.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/sign-up-detail",
		name: "SignUpDetail",
		component: () =>
			import(/* webpackChunkName: "sign-up" */ "../views/auth/signUpDetails.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: () =>
			import(/* webpackChunkName: "forgot-password" */ "../views/auth/ForgotPassword.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/resetpassword",
		name: "ResetPassword",
		component: () =>
			import(/* webpackChunkName: "forgot-password" */ "../views/auth/ResetPassword.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/resetprofilepassword",
		name: "ResetProfilePassword",
		component: () => import("../views/auth/ResetProfilePassword.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/kyc-info",
		name: "KnowYourCustomerInformation",
		component: () =>
			import(/* webpackChunkName: "know-your-customer-information" */ "../views/kyc/KnowYourCustomerInformation.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/kyc-documents",
		name: "KnowYourCustomerDocuments",
		component: () =>
			import(/* webpackChunkName: "know-your-customer-documents" */ "../views/kyc/KnowYourCustomerDocuments.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/design-system",
		name: "Design System",
		component: () =>
			import(/* webpackChunkName: "design-system" */ "../views/DesignSystem.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/shipments",
		name: "Shipments",
		component: () =>
			import(/* webpackChunkName: "shipments-list" */ "../views/shipments/ShipmentsList.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/orders",
		name: "Orders",
		component: () =>
			import(/* webpackChunkName: "shipments-list" */ "../views/shipments/ShipmentsList.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/shipments/:id",
		name: "Shipments Detail",
		component: () =>
			import("../views/shipments/ShipmentsDetail.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/orders/:id",
		name: "Order Detail",
		component: () =>
			import("../views/orders/OrderDetail.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/billing/dispute/:id",
		name: "Dispute",
		component: () =>
			import("../views/dispute/billingDispute.vue"),
		meta: { transitionName: "slide" }
		},
		,
		{
		path: "/billing/getdisputedetails/:id",
		name: "Dispute_Details",
		component: () =>
			import("../views/dispute/disputeDetails.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/shipments/edit",
		name: "Shipments Edit",
		component: () =>
			import(/* webpackChunkName: "shipments-edit" */ "../views/shipments/ShipmentsEdit.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/new",
		name: "New Order",
		component: () =>
			import(/* webpackChunkName: "new-order" */ "../views/new-order/NewOrder.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/manifests",
		name: "Manifests",
		component: () =>
			import(/* webpackChunkName: "manifests" */ "../views/manifests/Manifests.vue"),
		meta: { transitionName: "slide" }
		},
        {
            path: "/manifest/split",
            name: "ManifestSplit",
            component: () =>
              import(
                /* webpackChunkName: "manifests" */ "../views/manifests/ManifestSplit.vue"
              ),
            meta: { transitionName: "slide" },
        },
		{
		path: "/ndr",
		name: "Ndr",
		component: () =>
			import(/* webpackChunkName: "ndr" */ "../views/ndr/Ndr.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/dispute/reconciliation",
		name: "Reconciliation",
		component: () =>
			import(/* webpackChunkName: "ndr" */ "../views/dispute/ReConc.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/usermanagement",
		name: "User Management",
		component: () =>
			import(/* webpackChunkName: "ndr" */ "../views/userManagement/userManagement.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/support",
		name: "Support",
		component: () =>
			import("../views/support/DefaultSupport.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/support-ticket",
		name: "Support Ticket",
		component: () =>
			import(/* webpackChunkName: "support-ticket" */ "../views/support/SupportTicket.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/billing",
		name: "Billing",
		component: () =>
			import(/* webpackChunkName: "billing" */ "../views/billing/Billing.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/cod",
		name: "Cod",
		component: () =>
			import(/* webpackChunkName: "cod" */ "../views/cod/Cod.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/pricecalculator",
		name: "Calculator",
		component: () =>
			import(/* webpackChunkName: "calculator" */ "../views/calculator/Calculator.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/intlkyc",
		name: "intlkyc",
		component: () =>
			import(/* webpackChunkName: "calculator" */ "../components/intlkyc.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/reports",
		name: "Reports",
		redirect: '/analytics'
		// component: () =>
		// 	import(/* webpackChunkName: "reports" */ "../views/reports/Reports.vue"),
		// meta: { transitionName: "slide" }
		},
		{
			path: "/analytics",
			name: "Analytics",
			component: () => import("../views/analytics/Analytics.vue"),
			meta: { transitionName: "slide" }
		},
		{
		path: "/settings/serviceability",
		name: "PincodeCheck",
		component: () =>
			import(/* webpackChunkName: "pincode-check" */ "../views/PincodeCheck.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/webhook/sandbox",
		name: "SandboxWebhooks",
		component: () =>
			import(/* webpackChunkName: "pincode-check" */ "../views/webhooks/sandboxWebhook.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/webhooks/detail/:id",
		name: "Webhooks Detail",
		component: () =>
			import(/* webpackChunkName: "pincode-check" */ "../views/webhooks/Webhookdetails.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/webhook/logs/:id",
		name: "Webhooks Logs",
		component: () =>
			import(/* webpackChunkName: "pincode-check" */ "../views/webhooks/webhookLogs.vue"),
		meta: { transitionName: "slide" }
		},
		// {
		// path: "/priority",
		// name: "Priority",
		// component: () =>
		// 	import( webpackChunkName: "priority"  "../views/Priority.vue"),
		// meta: { transitionName: "slide" }
		// },
		{
		path: "/settings",
		name: "Settings",
		component: () =>
			import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
		meta: { transitionName: "slide" }
		},
		{
		path: "/masters",
		name: "Masters",
		component: () =>
			import(/* webpackChunkName: "settings" */ "../views/Masters/Masters.vue"),
		meta: { transitionName: "slide" }
		},
		{
			path: "/unauthorized",
			name: "Unauthorized",
			component: () => import("../views/Unauthorized.vue"),
			meta: { transitionName: "slide" }
	  	},
		{
			path: "/user-agreements",
			name: "UserAgreements",
			component: () => import("../views/UserAgreements.vue"),
			meta: { transitionName: "slide" }
		},
		{
			path: '**',
			name: 'PageNotFound',
			component: () => import("../views/PageNotFound.vue"),
			meta: { transitionName: "slide" }
	  	}	
	],
	scrollBehavior(to, from, savedPosition) {
		var appWrapper = document.getElementById('app-content');
		appWrapper.scrollTop = 0;
	}
});

router.beforeEach((to, from, next) => {
	if (! (unAuthenticatedRoutes.indexOf(to.name) > -1) && !store.getters.user.username) {
	    next({ name: 'LogIn' })
	}
	else if((unAuthenticatedRoutes.indexOf(to.name) > -1) && store.getters.user.username) {
		next({ name: 'Dashboard' })
	}
	else {
		next()
	}
})

export default router;