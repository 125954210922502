<template>
	<div class="notifications" v-click-outside="clickOutsideMenu">
		<b-button 
			v-b-toggle.sidebarNotifications 
			@click="bellUpdate"
			:class="{ 'has-notifications': $store.state.notifState}" 
			class="btn-icon-transparent px-0">
			<i class="icon-notifications fs20"></i>
		</b-button>
		<span class="count" v-if="$store.state.global.notificationCount">{{$store.state.global.notificationCount}}</span>
		<b-sidebar id="sidebarNotifications" right bg-variant="white" sidebar-class="notifications-side" no-header shadow v-model="visible" title="Sidebar">
			<div class="notifications-header">
				<div class="d-flex align-items-center w-100 pb-28">
					<i class="icon-notifications text-blue fs14 pr-12"></i>
					<h2 class="text-gray-900">
						Notifications
					</h2>

					<b-button variant="rounded ml-auto" @click="goAllSettings">
						<i class="icon-settings fs14"></i>
					</b-button>

					<b-button variant="rounded ml-auto" 
						v-b-toggle.sidebarNotifications 
						@click="loadNotification(10)">
						<i class="icon-close-variant fs14"></i>
					</b-button>
				</div>
			</div>
			<ul class="notifications-list">
				<li v-for="notification in notifications" :key="notification.id">
					<div class="d-flex w-100 align-items-center">
						<template v-if="notification.isRead == 0">
							<div class="status status-unread mr-8"></div>
						</template>
						<template v-else>
							<div class="status status-read mr-8"></div>
						</template>
						<span class="fs16 lh20 fw500 text-gray-900">
							{{ notification.created | date }}
						</span>
						<span class="ml-auto fs14 lh16 text-gray-400">
							{{ notification.created | time }}
						</span>
					</div>
					<span class="fs14 lh18 pt-8 d-block text-gray-600 text-left">
						{{ notificationEllipsize(notification.text) }}
						<a 
							href="#" 
							@click="fullNotification(notification)">
							More
						</a>
					</span>
				</li>
			</ul>

			<div class="d-flex justify-content-center mt-10 mb-10" v-if="isMoreNotification">
				<a 
					href="#"
					@click="loadNotification(notifications.length+10)"
					>See more...
				</a>
			</div>
			
		</b-sidebar>
		<seeMoreDialog v-if="fullText" :fullText="fullText" ref="seeFullNotificationDialog" />
	</div>
</template>

<script>
import vClickOutside from "v-click-outside";
import seeMoreDialog from "@/components/notificationDialog.vue"

export default {
	name: "NotificationSidebar",
	data() {
		return {
			visible: false,
			fullText: [],
			test: '',
			hasNotifications: false,
			notifications: [],
			seeMoreCheck: 10,
			isMoreNotification: false,
		};
	},

	components: {
		seeMoreDialog
	},

	directives: {
	clickOutside: vClickOutside.directive,
	},

	mounted() {
		
	},
	computed: {
	    authPages() {
	      const activeRoute = this.$route.fullPath;
	      if (
	        activeRoute.includes('login') ||
	        activeRoute.includes('sign-up') ||
	        activeRoute.includes('forgot-password') ||
	        activeRoute.includes('sign-up-detail') ||
	        activeRoute.indexOf("resetpassword")>=0
	      ) {
	        return true;
	      } else {
	        return false;
	      }
	    },
	  },

	methods: {

		goAllSettings(){
			this.$router.push('/settings#Customization')
			this.$store.commit('setGlobal', {'goCustom':true});
		},

		async bellUpdate() {
			
			let response = await this.axios.put('/v3/notification-bell', {bellIcon: 0});
			
			if(response.data.response.success) {
				this.$store.commit('setNotifstate', false)
				this.$store.commit('setGlobal', {notificationCount : ''})
				this.loadNotification(10);
			}
		},


		async loadNotification(offsetValue) {

			this.seeMoreCheck = offsetValue;

			let params = {offset: offsetValue}

  			let result = await this.axios.get('/v3/notifications', {params: params})

  			this.$store.commit('setNotifstate', result.data.response.showBell)

  			//Length of notification array.
  			let notificationArrayLength = result.data.response.notifications.length;

  			let notificationArray = [...result.data.response.notifications];

  			if ( notificationArrayLength - offsetValue == 1) {
  				this.isMoreNotification = true;

  				notificationArray.pop();
  				this.notifications = notificationArray
  			}
  			else if(notificationArrayLength - offsetValue == 0) {
  				this.isMoreNotification = false;
  				this.notifications = notificationArray
  			}
  			else {
  				this.isMoreNotification = false;
  				this.notifications = notificationArray
  			}

  		},

		async fullNotification(notification) {

			let params = {'isRead': 1, 'id': notification.id}

			let result = await this.axios.put('/v3/notifications', params);

			this.loadNotification(10);

			this.fullText = notification;

			setTimeout(()=> {
				this.openModelDailog()
			})
		},

		clickOutsideMenu() {
			// this.loadNotification(10);
			this.visible = false;
		},

		openModelDailog() {
			this.$refs.seeFullNotificationDialog.isOpen = !this.$refs.seeFullNotificationDialog.isOpen;
			this.bottomOrderMenuToggle = true;
		},
	},
};
</script>

<style lang="scss">

.btn.btn-icon-transparent.has-notifications .icon-notifications {
	color:#006eff;
}

.btn.btn-icon-transparent.has-notifications .icon-notifications::after {
	display:none !important;
}

.count {
	        width: 20px;
		    height: 20px;
		    line-height: 20px;
		    text-align: center;
		    display: block;
		    content: "";
		    position: absolute;
		    top: 0.9rem;
		    color: #fff;
		    right: 1.1rem;
		    border-radius: 50%;
		    background-color: #FF8246;

		    @include media-breakpoint-down(sm) {
		    	right:3.2rem !important;
		    }
}

.notifications {
	display: flex;
	align-items: center;

	.notifications-side {
		width: rem(288px);
		overflow-y: hidden;

		@include media-breakpoint-down(sm) {
			top: rem(75px);
			height: auto;
			width: 100%;
			z-index: 9;
			bottom: 0;
		}

		.notifications-header {
			display: flex;
			flex-direction: column;
			padding: rem(32px) rem(32px) 0 rem(24px);

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		.notifications-list {
			list-style-type: none;
			padding: 0 rem(32px) 0 rem(26px);
			margin: 0;
			overflow-y: scroll;
			scrollbar-width: none;
			li {
				padding: rem(20px) 0;
			}
			.status {
				width: rem(8px);
				height: rem(8px);
				border-radius: 50%;
				&-read {
					background-color: #e3e7ee;
				}
				&-unread {
					background-color: #FF8246;
				}
			}
		}

		.b-sidebar-body {
			scrollbar-width: none;
		}
	}
}
</style>
