<template>
	<div class="auth log-in">
		<div class="auth-wrapper">
			<div class="card mw468">
				<b-form @submit.prevent="login" class="card__content pt-60 pb-52 px-40 px-md-56">

					<img src="@/assets/svg/logo_full.svg" alt="" class="d-block w-100 mw170 mx-auto mb-60" />
					<b-form-group id="email-field" label="Email" label-for="email" class="mb-28">
						<b-form-input type="email" required id="email" v-model="email" placeholder="Your email ID" trim></b-form-input>
					</b-form-group>


					<b-form-group id="password-field" label="Password" label-for="password" class="mb-40" :invalid-feedback="invalidFeedback" :valid-feedback="validFeedback" :state="state">
						<b-form-input id="password" required v-model="password" type="password" placeholder="Your secure password" trim></b-form-input>
					</b-form-group>

					<input id="hutk_cookie" required :value="hubspotcookie" type="hidden"></input>

					<b-button type="submit" :disabled="submitting" variant="primary" size="lg" class="w-100 mb-20 mb-md-40">
						{{ loginbtn }} <b-spinner v-if="submitting" class="ml-10"></b-spinner>
					</b-button>


					<div class="d-flex flex-column align-items-center">
						<b-link href="/forgot-password" class="text-underline mb-12 mb-md-16">
							Forgot your password?
						</b-link>
						<span class="fs12 lh20 text-gray-800">
							New here?
							<b-link href="/sign-up">Sign Up</b-link>
						</span>
					</div>


				</b-form>
			</div>

			<div class="mob_footer bottom-bar d-flex justify-content-between mx-40">
				<span class="fs12 lh20 text-gray-400 mb-16 mb-md-32">
					© 2021 Shyplite — All Rights Reserved.
				</span>
				<ul class="lsnone p-0 mb-16 mb-md-32">
					<li>
						<b-link target="_blank" href="https://shyplite.com/tou">Terms</b-link>
						<b-link class="ml-20" target="_blank" href="https://shyplite.com/privacy">Privacy</b-link>
						<b-link class="ml-20" target="_blank" href="https://shyplite.com/contact/">Contact Us</b-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>

// import VueFreshchat from "vue-freshchat";

import Vue from "vue";
// Vue.use(VueFreshchat, { appToken: "d760d5f2-f4aa-433b-abc3-a7abffd1d2ca" });

export default {
	name: "LogIn",
	title: "Shyplite - Log In",
	data() {
		return {
			email: "",
			password: "",
			state: null,
			loginbtn: 'Log In',
			submitting: false,
			hubspotcookie: '',
		};
	},
	mounted() {
		document.querySelectorAll('a').forEach(i => {
			let href = i.getAttribute('href');
			if(window.location.search) {
				i.setAttribute('href', `${href}${window.location.search}`);
			}
		})
	},
	computed: {
		invalidFeedback() {
			return "Wrong email or password.";
		},
		validFeedback() {
			return "Thank you";
		},
		user() {
			return this.$store.getters.user;
		},
	},

	created() {
		// this.$freshchat.hideWidget();
        this.hubspotcookie = (document.cookie.split(";").find(x => x.includes("hubspotutk=")) || "").trim().split("=").pop();

	},

	methods: {

		async login() {
			try {
				this.submitting = true
				this.loginbtn = 'Logging In'
				const res = await this.axios.post("/login?app=slv", { "emailID": this.email, "password": this.password, "hubspotcookie": this.hubspotcookie});
				if(res.data.user) {
					const user = res.data.user
					user.registrationCompleted = res.data.user.profileStatus==2 ? true : false
					user.sellerStatus = res.data.user.profileStatus!=2 //  (New User)
					 || (res.data.user.profileStatus==2 && res.data.user.status==8) // Disapproved KYC docs
					  ? 0 : 
					  res.data.user.profileStatus==2 && (res.data.user.status==9 || res.data.user.status==3) // Pending Approval
					  ? 2 : 
					  1 // Approved or Activated
					user.avatarImage = require("@/assets/img/user.png")
					await this.getOtherInfo()
					this.$store.dispatch('login', user)
					this.state = true;
					if (user.ukvat) {
						this.$store.commit('setGlobal', {'ukvat':user.ukvat})
					}
				}else{
					this.popToast("failed", "Failed", res.data.message);
					this.loginbtn = 'Log In'
					this.submitting = false
				}
			}
			catch(err) {
				console.log(err);
				this.state = false;
				this.submitting = false
				this.loginbtn = 'Log In'
			}
		},

		async getOtherInfo() {

			// GRAPH DATA...
			const now = Math.floor(Date.now()/1000)
			const date = new Date();
			const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
			const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

			try {
				const chart = await this.axios.get(`/v3/dashboard/graphround/v2?from=${firstDay.getTime()}&to=${lastDay.getTime()}`)
				if(chart.data.success === true) {
					this.$store.commit('setUser', {chart_timestamp: now + 3*3600})
					this.$store.commit('setCharts', chart.data.data)
				}

				// OVERVIEW DATA...
				const overview = await this.axios.get('/v3/dashboard/overview')
				this.$store.commit('setGlobal', {overview: overview.data})

			} catch(e) {
				console.log(e);
			}

			// NOTIFICATIONS DATA...
			//const notice = await this.axios.get('/notification')
			//if(notice.data.success) {
			//	this.$store.commit('setNotifications', notice.data.notifications)
			//}


			// GET STATICS VARIABLES...
			// const statics = await this.axios.get('/statics')
			// this.$store.commit('setModes', statics.data.modes.filter(item => item.name != ''))
			// this.$store.commit('setCarriers', statics.data.carriers.filter(item => item.name != ''))
			// this.$store.commit('setChannels', statics.data.channels.filter(item => item.name != ''))
			// this.$store.commit('setAllmodes', {'mps':statics.data.mps, 'b2b':statics.data.b2b, 'intl':statics.data.intl, 'domestic':statics.data.domestic})


			// GET WAREHOUSE LIST...
			let warehouses = await this.axios.get('/warehouse/list')
			this.$store.commit('setWarehouses', warehouses.data)

			// SETTINGS API DETAILS...
			const apires = await this.axios.get('/settings/api')
			let api = {}
			if(apires.data.api === false) {
				api.status = 'disable'
			} else {
				api = apires.data.api
				api.status = 'enable'
			}
			this.$store.commit('setAPICred', api);
		}
	},
};
</script>
