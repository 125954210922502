import Shepherd from "shepherd.js";

export const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: "shadow-md bg-purple-dark",
      scrollTo: {
        behavior: 'smooth',
        block: 'center'
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 18] } }]
      },
      cancelIcon: {
        enabled: true
      }
    },
    steps: true
  });